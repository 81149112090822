import React from "react";

const Badge = ({ text }) => {
  return (
    <div
      className="bg-orange-500 w-[60px] h-[30px] text-white flex justify-center items-center text-base font-semibold hover:bg-orange-600 duration-300 cursor-pointer"
      style={{ borderRadius: "10%" }}
    >
      {text}
    </div>
  );
};

export default Badge;
