import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import mpesaimage from "../../assets/payment/mpesapay.jpg";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import useSession from "../../context/useSession";
import PaymentModal from "./PaymentModal";

const Payment = () => { 
  const location = useLocation();
  const { authToken } = useSession();
  const { search } = useLocation();
  const [checkoutId, setCheckoutId ] = useState(()=>{
    const id = sessionStorage.getItem("checkoutId")
    return id
  })
  const [mobileNumber, setMobileNumber] = useState("254");
  const [deliveryType, setDeliveryType] = useState("door");
  const [receiverName, setReceiverName] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [receiverMobile, setReceiverMobile] = useState("");
  const [address, setAddress] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const amount = queryParams.get("totalAmount");
    if (amount) {
      setTotalAmount(decodeURIComponent(amount));
    }
  }, [search]);

  const handleInputChangeMobile = (e) => {
    let value = e.target.value.replace(/\s+/g, "");
    if (!value.startsWith("254")) {
      value = "254" + value.slice(3);
    }
    setMobileNumber(value);
  };

  const handleCompleteSale = async () => {
    if (mobileNumber.startsWith("254") && mobileNumber.length === 12) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/payments/create_order`,
          {
            amount: totalAmount,
            address,
            receiverName,
            receiverEmail,
            mobileNumber,
            deliveryType,
            receiverMobile,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        // console.log(response)
        if(response?.data?.CheckoutRequestID){
          sessionStorage.setItem("checkoutId",response?.data?.CheckoutRequestID)
          setCheckoutId(response?.data?.CheckoutRequestID)
        }

        notification.info(
          {
            message:"Your payment is being processed.",
            description:"Please check your phone to confirm payment"
          }
          
        );
      } catch (error) {
        if (error?.response?.status === 400) {
          notification.success({
            message: error?.response?.data?.message || "Oops! an error occurred.",
          });

        } else {
          notification.success({
            message: "An error occurred while processing your payment. Please try again."
          }
           
          );
        }
        // console.error("Error completing payment:", error);
      }
    } else {
      MySwal.fire({
        title: "Invalid Input",
        text: "Please check your mobile number and try again.",
        icon: "error",
        confirmButtonText: "Try Again",
        confirmButtonColor: "#FF6347",
      });
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-8 py-12 bg-white  rounded-lg">
      <Breadcrumbs title="Payment Gateway" />
      {
        checkoutId ? <PaymentModal setCheckoutId={(prev)=>setCheckoutId(prev)} checkoutId={checkoutId}/>:<div className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-8">
        {/* Delivery Details Form */}
        <div className="w-full md:w-1/2 bg-gray-50 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold text-gray-700 mb-6">
            Delivery Details
          </h3>
          <form className="space-y-6">
            <div>
              <select
                value={deliveryType}
                onChange={(e) => setDeliveryType(e.target.value)}
                className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 focus:border-primeColor focus:ring-primeColor outline-none"
              >
                <option value=" ">Select delivery mode</option>
                <option value="door_delivery">Door Delivery</option>
                <option value="pickup">Physical Pickup</option>
              </select>
            </div>

            <div>
              <input
                type="text"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 focus:border-primeColor focus:ring-primeColor outline-none"
                placeholder="Enter receiver's name"
              />
            </div>

            <div>
              <input
                type="email"
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 focus:border-primeColor focus:ring-primeColor outline-none"
                placeholder="Enter receiver's email"
              />
            </div>

            <div>
              <input
                value={receiverMobile}
                onChange={(e) => setReceiverMobile(e.target.value)}
                className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 focus:border-primeColor focus:ring-primeColor outline-none"
                placeholder="Enter receiver's mobile"
              />
            </div>

            <div>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 focus:border-primeColor focus:ring-primeColor outline-none"
                placeholder="Enter address here"
              />
            </div>
          </form>
        </div>

        {/* Payment Details */}
        <div className="w-full md:w-1/2 bg-gray-50 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold text-gray-700 mb-6">
            Payment Details
          </h3>
          <img
            src={mpesaimage}
            alt="Payment Image"
            className="w-full h-24 object-contain"
          />

          <div>
            <label className="block text-base font-medium text-gray-800 mb-2 mt-2">
              Mobile Number
            </label>
            <input
              type="text"
              value={mobileNumber}
              onChange={handleInputChangeMobile}
              className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 focus:border-primeColor focus:ring-primeColor outline-none"
              placeholder="Enter mobile number"
            />
          </div>

          <div>
            <label className="block text-base font-medium text-gray-800 mb-2 mt-2">
              Total amount to pay
            </label>
            <input
              type="text"
              value={totalAmount}
              readOnly
              className="w-full px-4 py-3 border rounded-md shadow-sm text-base border-gray-300 bg-gray-100"
            />
          </div>

          <button
            type="button"
            onClick={handleCompleteSale}
            className="w-full px-4 mt-4 py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Pay now
          </button>
        </div>
      </div>
      }
      
      
    </div>
  );
};

export default Payment;
