import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import useSession from "../../context/useSession";
import { useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import AddToCartModal from "./AddToCartModal";
import UpdatePriceModal from "./UpdatePriceModal";
import { motion } from "framer-motion";

const tabs = [
  { id: "Specifications", label: "Specifications" },
  { id: "Sizes", label: "Sizes" },
  { id: "Description", label: "Description" },
];

const ProductPage = () => {
  const { id } = useParams();
  const productId = id;
  const { authToken, user } = useSession();

  const [rating, setRating] = useState(0);
  const [avgRating, setAvgRating] = useState({});
  const [product, setProductDetails] = useState({});
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log(selectedColors);

  const handleAddToCartClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleClick = (value) => {
    setRating(value);
    submitRating(value);
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
    setSelectedColors(size.colors || []);
    const firstColor = size.colors[0];
    const firstImage = firstColor?.images[0]?.imageurl || "";
    setSelectedImage(firstImage);
  };

  // Handle color click to update selected image
  const handleColorClick = (colorId) => {
    const selectedColor = selectedColors.find((color) => color.id === colorId);
    console.log("Selected Color:", selectedColor);
    if (selectedColor) {
      const defaultImage = selectedColor.images[0]?.imageurl || "";
      console.log("Default Image:", defaultImage);
      setSelectedImage(defaultImage);
    }
  };

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_product/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const productData = response.data.product;
      setProductDetails(productData);
      console.log(productData);

      if (productData.sizes.length > 0) {
        const initialSize = productData.sizes[0];
        setSelectedSize(initialSize);
        setSelectedColors(initialSize.colors || []);
        const initialColor = initialSize.colors[0];
        setSelectedImage(initialColor.images[0]?.imageurl || "");
      }
    } catch (error) {
      console.error("Error fetching product", error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [authToken, id]);

  const handlePriceUpdate = () => {
    fetchProductDetails();
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [avgRatingResponse] = await Promise.all([fetchAvgRating()]);
      setAvgRating(avgRatingResponse.data.averageRating);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAvgRating = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/avg_ratings/${productId}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  // Submitting a rating
  const submitRating = async (rating) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/ratings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: user.id,
            productId: +id,
            rate: rating,
          }),
        }
      );

      if (response.ok) {
        console.log("Rating submitted successfully");
        fetchData();
      } else {
        console.log("Failed to submit rating");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderDescription = () => (
    <p className="text-base text-gray-600">
      {product.desc || "No description available"}
    </p>
  );

  const renderSpecifications = () => {
    if (!product.specification || product.specification.length === 0) {
      return <p>No specifications available</p>;
    }

    return (
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="border px-4 py-2 text-left">Attribute</th>
            <th className="border px-4 py-2 text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          {product.specification.map((spec, index) => (
            <React.Fragment key={index}>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">Material</td>
                <td className="border px-4 py-2">{spec.material}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">
                  No of Pockets
                </td>
                <td className="border px-4 py-2">{spec.noOfPockets}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">Handle</td>
                <td className="border px-4 py-2">{spec.handle}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">Straps</td>
                <td className="border px-4 py-2">{spec.straps}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">Lock</td>
                <td className="border px-4 py-2">{spec.lock}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">Wheels</td>
                <td className="border px-4 py-2">{spec.wheels || 0}</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border px-4 py-2 font-semibold">
                  Water Resistance
                </td>
                <td className="border px-4 py-2">{spec.waterResistance}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  console.log("selectedColors", selectedColors);

  const renderSizes = () => {
    if (!product.sizes || product.sizes.length === 0) {
      return <p>No sizes available</p>;
    }

    return (
      <div>
        <h3 className="text-lg font-semibold">Available Sizes</h3>
        <div className="flex flex-wrap gap-4 mt-2">
          {product.sizes.map((size) => (
            <button
              key={size.id}
              onClick={() => handleSizeClick(size)}
              className={`px-4 py-2 rounded ${
                selectedSize?.id === size.id
                  ? "bg-orange-600 text-white"
                  : "bg-slate-200"
              }`}
            >
              {size.sizeName.name}
            </button>
          ))}
        </div>
      </div>
    );
  };

  // Render colors based on selected size
  const renderColors = () => {
    if (!selectedColors || selectedColors.length === 0) {
      return <p>No colors available</p>;
    }

    return (
      <div>
        <h3 className="text-lg font-semibold">Available Colors</h3>
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedColors.map((color) => (
            <div>
              <button
                key={color.id}
                onClick={() => handleColorClick(color.id)}
                className={`w-8 h-8 rounded-full ${
                  color.id === selectedColors[0]?.id ? "shadow-md" : ""
                }`}
                style={{ backgroundColor: color.colorName.name }}
              />
              {/* <p>{color.count}</p> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <motion.div
      className="w-full mx-auto border-b-[1px] border-b-gray-300"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={window.location.pathname} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="h-full xl:col-span-2">
            {selectedImage && (
              <div className="h-full xl:col-span-2">
                <img
                  className="w-full h-96 object-contain"
                  src={selectedImage}
                  alt={product.name}
                />
                <div className="flex mt-2 gap-2">
                  {selectedSize.colors
                    .flatMap((color) => color.images)
                    .map((img) => (
                      <img
                        key={img.id}
                        className="w-20 h-20 object-contain cursor-pointer border-2 border-gray-200 hover:border-blue-500"
                        src={img.imageurl}
                        alt={`Thumbnail ${img.id}`}
                        onClick={() => setSelectedImage(img.imageurl)}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
            <div className="flex flex-col gap-5">
              <h2 className="text-4xl font-semibold">
                {product.name}{" "}
                <span className="text-lg text-orange-600 ml-4">
                  ({Number(avgRating).toFixed(1)}{" "}
                  <FaStar className="inline text-sm" />)
                </span>
              </h2>

              <p className="text-2xl font-semibold">
                {selectedSize
                  ? (() => {
                      const priceTracker = selectedSize.priceTrackers.find(
                        (tracker) => tracker.status === "decrease"
                      );

                      if (priceTracker) {
                        return (
                          <>
                            <span className="line-through text-sm text-orange-600">
                              KES {priceTracker.previousPrice}
                            </span>
                            <span className="ml-2">
                              KES {priceTracker.currentPrice}
                            </span>
                          </>
                        );
                      } else {
                        return <span>KES {selectedSize.price}</span>;
                      }
                    })()
                  : "N/A"}
                {selectedSize
                  ? (() => {
                      const priceTracker = selectedSize.priceTrackers.find(
                        (tracker) => tracker.status === "decrease"
                      );

                      if (priceTracker && priceTracker.changeAmount > 0) {
                        return (
                          <span className="text-xs ml-2 inline-flex items-center px-3 py-1 rounded-full bg-orange-600 text-white">
                            {`Save KES ${priceTracker.changeAmount}`}
                          </span>
                        );
                      }

                      return null;
                    })()
                  : null}
              </p>

              <hr />

              {renderDescription()}

              {/* review */}
              <div className="flex items-center">
                <p className="text-sm mr-2">Leave a review:</p>
                {[1, 2, 3, 4, 5].map((star) => (
                  <svg
                    key={star}
                    className={`w-4 h-4 ms-1 ${
                      star <= rating ? "text-yellow-300" : "text-gray-300"
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                    onClick={() => handleClick(star)}
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                ))}
              </div>

              <div className="flex gap-2">
                <p className="text-base text-green-600 font-medium">
                  Currently in stock
                </p>
                {/* {user.isUser === false && <span>({selectedSize?.count})</span>} */}
              </div>

              {user.isUser === false && (
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="p-2 bg-blue-500 text-white rounded-md"
                >
                  Update Price
                </button>
              )}

              <UpdatePriceModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                product={product}
                onPriceUpdate={handlePriceUpdate}
              />

              {renderSizes()}
              {renderColors()}

              <button
                onClick={handleAddToCartClick}
                className="bg-orange-600 text-white px-4 py-2 rounded mt-4"
              >
                Add to Cart
              </button>
              <AddToCartModal
                show={showModal}
                handleClose={handleCloseModal}
                product={product}
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-200  mb-8">
          <div className="max-w-container mx-auto">
            <div className="tabs mt-6 flex">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`px-4 py-2 font-medium ${
                    activeTab === tab.id
                      ? "border-b-2 border-orange-500 bg-orange-600 text-white"
                      : ""
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className="p-6">
              {activeTab === "Specifications" && renderSpecifications()}
              {activeTab === "Sizes" && renderSizes()}
              {activeTab === "Description" && renderDescription()}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductPage;
