import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminIndex from "./Admin/AdminIndex";
import HeaderAdmin from "./Admin/Components/Header/Header";
import HeaderBottomAdmin from "./Admin/Components/Header/HeaderBottomAdmin";
import AdminLogin from "./Admin/Components/Login/AdminLogin";
import Orders from "./Admin/Components/OrdersView/Orders";
import RegisterProduct from "./Admin/Components/ProductView/RegisterProduct";
import Sales from "./Admin/Components/Sales/Sales";
import AdminSignUp from "./Admin/Components/SignUp/AdminSignUp";
import Customers from "./Admin/Components/UsersView/Customers";
import UserDetails from "./Admin/Components/UsersView/UserDetails";
import Settings from "./Admin/pages/Settings/Settings";
import BannerBottom from "./components/Banner/BannerBottom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import ProductPage from "./components/products/ProductPage";
import ScrollToTop from "./components/ScrollTop/ScrollToTop";
import FeedbackAnchor from "./components/SpecialCase/FeedbackAnchor";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import ProtectedRouteWrapper from "./context/ProtectedRouteWrapper";
import ForgotPassword from "./context/ResetPassword/ForgotPassword";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Payment from "./pages/payment/Payment";
import Shop from "./pages/Shop/Shop";
import "react-toastify/dist/ReactToastify.css";
import CategoryProducts from "./pages/Shop/CategoryProducts";

const Layout = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BannerBottom />
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <FeedbackAnchor />
      <ScrollRestoration />
      <ScrollToTop />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};

const AdminLayout = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BannerBottom />
      <HeaderAdmin />
      <HeaderBottomAdmin />
      {/* <SpecialCase /> */}
      <ScrollRestoration />
      <ScrollToTop />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>

        {/* <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route> 
        <Route path="/journal" element={<Journal />}></Route> */}
        {/* <Route path="/category/:category" element={<Offer />}></Route> */}
        <Route path="/category/:categorySlug" element={<CategoryProducts />} />

        <Route path="/" element={<ProtectedRouteWrapper isUser={1} />}>
          <Route path="/feedback" element={<Contact />}></Route>
          <Route path="product/:id" element={<ProductPage />}></Route>
          <Route path="cart" element={<Cart />}></Route>
          <Route path="payment_gateway" element={<Payment />}></Route>
        </Route>
      </Route>

      <Route path="/admin" element={<ProtectedRouteWrapper isUser={0} />}>
        <Route element={<AdminLayout />}>
          <Route index element={<AdminIndex />}></Route>
          <Route path="register_item" element={<RegisterProduct />}></Route>
          <Route path="admin_orders" element={<Orders />}></Route>
          <Route path="customers" element={<Customers />}></Route>
          <Route path="customers/:id" element={<UserDetails />}></Route>
          <Route path="sales" element={<Sales />}></Route>
          <Route path="product/:id" element={<ProductPage />}></Route>
          <Route path="settings" element={<Settings />}></Route>
        </Route>
      </Route>

      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<AdminLogin />}></Route>

      <Route path="/admin_login" element={<AdminLogin />}></Route>
      <Route path="/admin_signup" element={<AdminSignUp />}></Route>

      {/* reset password */}
      <Route path="/reset_password" element={<ForgotPassword />}></Route>
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
