import React, { useContext, useState } from "react";
import { BsCheckCircleFill, BsArrowRepeat } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import useSession from "../../../context/useSession";
import { SessionContext } from "../../../context/SessionContext";

import { notification } from "antd";

const AdminLogin = () => {
  const { login, errorMsg, successMsg, loading } = useSession();
  const { user } = useContext(SessionContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
      return;
    }

    if (!password) {
      setErrPassword("Enter your password");
      return;
    }

    const success = await login(email, password);
    if (success) {
      navigate(success.isUser ? "/" : "/admin");
    }
  };

  return (
    <div className="w-full md:h-screen lg:h-screen xl:h-screen flex flex-col flex-col-reverse md:flex-row lg:flex-row xl:flex-row items-center justify-center">
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 lgl:inline-flex h-full text-white">
        <div className="w-full md:w-[550px] lg:w-[550px]  xl:w-[550px]  h-full bg-gradient-to-r from-blue-700 to-blue-800 px-2 py-4 md:py-0 md:px-10 flex flex-col gap-6 justify-center">
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Stay signed in for more
            </h1>
            <p className="text-base">When you sign in, you are with us!</p>
          </div>
          <div className="w-full md:w-[400px] lg:w-[400px] xl:w-[400px] flex items-start gap-3">
            <span className="text-yellow-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with Vintara Collections
              </span>
              <br />
              Discover the elegance and craftsmanship of the Vintara Collection,
              where timeless design meets modern functionality.
            </p>
          </div>
          <div className="w-full md:w-[400px] lg:w-[400px] xl:w-[400px] flex items-start gap-3">
            <span className="text-yellow-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all Vintara Collection services
              </span>
              <br />
              Unlock the full potential of the Vintara Collection with our
              exclusive services. From personalized styling advice to seamless
              online shopping experiences.
            </p>
          </div>
          <div className="w-full md:w-[400px] lg:w-[400px] xl:w-[400px] flex items-start gap-3">
            <span className="text-yellow-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
              Join thousands of satisfied customers who trust Vintara for their
              online shopping need.
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
                © Vintara Collections
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 h-full">
        {successMsg ? (
          <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button className="w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300">
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <form
            className="w-full lgl:w-[450px] mt-5 mb-10 md:mt-0 md:mb-0 md:h-screen md:h-screen md:h-screen flex items-center justify-center"
            onSubmit={handleSignIn}
          >
            <div className="px-6 py-4 w-full h-[80%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor md:shadow-lg">
              <span className="font-semibold md:font-bold md:text-xl text-base pt-10 pb-4">Hello dear customer. We are pleased to have you.</span>
              <h1 className="font-titleFont font-semibold text-xl mdl:text-2xl mb-10">
                Sign in to continue shopping.
              </h1>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-10 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400 outline-orange-500"
                    type="email"
                    placeholder="Email address"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handlePassword}
                    value={password}
                    className="w-full h-10 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400 outline-orange-500"
                    type="password"
                    placeholder="Password"
                  />
                  {errPassword && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPassword}
                    </p>
                  )}
                </div>

                {errorMsg && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errorMsg}
                  </p>
                )}

                <button
                  type="submit"
                  className={
                    "w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300 flex items-center justify-center"
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <BsArrowRepeat className="animate-spin mr-2" />
                  ) : (
                    "Sign In"
                  )}
                </button>

                <p className="text-sm text-center font-titleFont font-medium">
                  Forgot password?{" "}
                  <a href="/reset_password">
                    <span className="text-blue-500 hover:text-blue-600 duration-300">
                      Reset
                    </span>
                  </a>
                </p>

                <p className="text-sm text-center font-titleFont font-medium">
                  Don't have an Account?{" "}
                  <Link to="/signup">
                    <span className="text-blue-500 hover:text-blue-600 duration-300">
                      Sign up
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
