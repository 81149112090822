import React, { useState } from "react";
import { BsCheckCircleFill, BsArrowRepeat } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";

const SignUp = () => {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const [errfullName, setErrfullName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errphoneNumber, setErrphoneNumber] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleName = (e) => {
    setfullName(e.target.value);
    setErrfullName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlephoneNumber = (e) => {
    setphoneNumber(e.target.value);
    setErrphoneNumber("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (
      fullName &&
      email &&
      EmailValidation(email) &&
      phoneNumber &&
      password &&
      password.length >= 6
    ) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/user`,
          {
            fullName,
            email,
            phoneNumber,
            password,
          }
        );
        console.log("Response:", response); // Check the response

        if (response.status === 201) {
          notification.success({
            message: "Account created successfully",
            description: `Hello ${fullName}, Welcome to Vintara Africa. Enjoy your shopping adventure.`,
          });
          setfullName("");
          setEmail("");
          setphoneNumber("");
          setPassword("");
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error:", error); // Log the error
        const errorMessage =
          error.response?.data?.message ||
          "There was an error creating your account. Please try again later.";
        notification.error({
          message: "Sign Up Failed",
          description: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    } else {
      notification.warning({
        message: "Form Incomplete",
        description: "Please fill in all fields correctly before submitting.",
      });
    }
  };

  return (
    <div className="w-full md:h-screen lg:h-screen xl:h-screen flex flex-col flex-col-reverse md:flex-row lg:flex-row xl:flex-row items-center justify-start">
      <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 lgl:inline-flex h-full text-white">
        <div className="w-full md:w-[550px] lg:w-[550px]  xl:w-[550px]  h-full bg-gradient-to-r from-blue-700 to-blue-800 px-2 py-4 md:py-0 md:px-10 flex flex-col gap-6 justify-center">
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Hello dear customer, welcome.
            </h1>
            <p className="text-base">Create your account to access more</p>
          </div>
          <div className="w-full md:w-[400px] lg:w-[400px] xl:w-[400px] flex items-start gap-3">
            <span className="text-yellow-400 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with Vintara Collection
              </span>
              <br />
              Discover the elegance and craftsmanship of the Vintara Collection,
              where timeless design meets modern functionality.
            </p>
          </div>
          <div className="w-full md:w-[400px] lg:w-[400px] xl:w-[400px] flex items-start gap-3">
            <span className="text-yellow-400 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all Vintara Collection services
              </span>
              <br />
              Unlock the full potential of the Vintara Collection with our
              exclusive services.
            </p>
          </div>
          <div className="w-full md:w-[400px] lg:w-[400px] xl:w-[400px] flex items-start gap-3">
            <span className="text-yellow-400 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
              Join thousands of satisfied customers who trust Vintara for their
              online shopping needs.
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              © Vintara Collection
            </p>
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-yellow-400 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
        {successMsg ? (
          <div className="w-[500px]">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signin">
              <button className="w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300">
                Sign in
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full lgl:w-[450px] mt-5 mb-10 md:mt-0 md:mb-0 md:h-screen md:h-screen md:h-screen flex items-center justify-center">
            <div className="px-6 py-4 w-full h-[80%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor md:shadow-lg">
              <span className="font-semibold text-sm md:font-bold md:text-base text-base pt-10 pb-4">
                Hello dear customer. We are pleased to have you.
              </span>

              <h1 className="font-titleFont font-semibold text-xl mdl:text-xl mb-4">
                Create your account and proceed to experience the vintara elegance.
              </h1>
              <div className="flex flex-col gap-3">
                {/* client name */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handleName}
                    value={fullName}
                    className="w-full h-10 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-orange-500"
                    style={{ outlineWidth: "0.5px" }}
                    type="text"
                    placeholder="Full name"
                  />
                  {errfullName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errfullName}
                    </p>
                  )}
                </div>
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-10 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-orange-500"
                    type="email"
                    placeholder="Email address"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>
                {/* phoneNumber Number */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handlephoneNumber}
                    value={phoneNumber}
                    className="w-full h-10 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-orange-500"
                    type="text"
                    placeholder="phoneNumber number"
                  />
                  {errphoneNumber && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errphoneNumber}
                    </p>
                  )}
                </div>
                {/* Password */}
                <div className="flex flex-col gap-.5">
                  <input
                    onChange={handlePassword}
                    value={password}
                    className="w-full h-10 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[0.5px] border-gray-400  outline-orange-500"
                    type="password"
                    placeholder="Password"
                  />
                  {errPassword && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPassword}
                    </p>
                  )}
                </div>

                <button
                  onClick={handleSignUp}
                  className={
                    "w-full bg-gradient-to-r from-blue-700 to-blue-800 text-white py-4 rounded-lg hover:bg-gradient-to-l hover:from-blue-800 hover:to-blue-900 transition duration-300 flex items-center justify-center"
                  }
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                    <BsArrowRepeat className="animate-spin mr-2" />
                  ) : (
                    "Create Account"
                  )}
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  Already have an Account?{" "}
                  <Link to="/admin_login">
                    <span className="hover:text-blue-600 duration-300">
                      Sign in
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignUp;
