import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import useSession from "../../../../context/useSession";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toggleColor } from "../../../../redux/orebiSlice";

const ColorFilter = () => {
  const [showColors, setShowColors] = useState(true);
  const dispatch = useDispatch();
  const { authToken } = useSession();

  const selectedColors = useSelector(
    (state) => state.orebiReducer.checkedColors
  );

  const [colors, setColors] = useState([]);

  const handleColorToggle = (color) => {
    const colorObj = { name: color };
    console.log("Toggling color:", colorObj);
    dispatch(toggleColor(colorObj));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [colorsResponse] = await Promise.all([fetchColors()]);
      setColors(colorsResponse.data.data);
      console.log(colorsResponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchColors = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  useEffect(() => {
    console.log("Current selectedColors:", selectedColors);
  }, [selectedColors]);

  return (
    <div className="color-filter">
      <div
        onClick={() => setShowColors(!showColors)}
        className="cursor-pointer"
      >
        <NavTitle title="Shop by Color" icons={true} />
      </div>
      {showColors && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            {colors.map((item) => (
              <li
                key={item.id}
                className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2"
              >
                <input
                  type="checkbox"
                  value={item.name}
                  checked={selectedColors.some(
                    (color) => color.name === item.name
                  )}
                  onChange={() => handleColorToggle(item.name)}
                />
                <span
                  style={{ background: item.name }}
                  className={`w-3 h-3 bg-gray-500 rounded-full`}
                ></span>
                {item.name}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default ColorFilter;
