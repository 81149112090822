import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import vintone from "../../assets/images/banner/vintone.png";
import bannertwo from "../../assets/images/banner/brown_vintara_without_bg.png"
import bannerthree from "../../assets/images/banner/black_vintara_without_bg.png"


const CustomSlide = ({ Subtext, imgSrc, text, buttonLink, buttonText }) => (
  <div
    style={{
      position: "relative",
      backgroundColor: "#F5F5F3",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      flexDirection: window.innerWidth < 768 ? "column" : "row", 
    }}
  >
    <div
      style={{
        maxWidth: window.innerWidth < 768 ? "100%" : "450px", 
        marginRight: window.innerWidth < 768 ? "0" : "100px", 
        textAlign: window.innerWidth < 768 ? "center" : "left", 
      }}
    >
      <h1
        style={{
          marginBottom: "15px",
          fontSize: window.innerWidth < 768 ? "1.8rem" : "2.5rem", // Smaller font size on small screens
          color: "#000",
          fontWeight: "700",
        }}
      >
        {text}
      </h1>
      <p
        style={{
          marginBottom: "25px",
          fontSize: window.innerWidth < 768 ? "1rem" : "1.5rem", // Smaller font size on small screens
          color: "#666",
        }}
      >
        {Subtext}
      </p>

      <a href={buttonLink}>
        <button className="bg-orange-500 text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-orange-600 duration-300 font-bold rounded">
          {buttonText}
        </button>
      </a>
    </div>
    <div className="max-w-[350px]" style={{ marginLeft: window.innerWidth < 768 ? "0" : "100px", marginTop: window.innerWidth < 768 ? "20px" : "0" }}>
      <img src={imgSrc} style={{width:'100%',height:'calc(100vh - 100px)',objectFit:'contain',objectPosition:'center'}}/>
    </div>
  </div>
);

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: window.innerWidth < 768 ? "5%" : "7%",
          transform: "translateY(-50%)",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: window.innerWidth < 768 ? "20px" : "30px",
                color: "#ea580c",
                borderRight: "4px #ea580c solid",
                padding: window.innerWidth < 768 ? "5px 0" : "8px 0",
                cursor: "pointer",
              }
            : {
                width: window.innerWidth < 768 ? "20px" : "30px",
                color: "transparent",
                borderRight: "4px white solid",
                padding: window.innerWidth < 768 ? "5px 0" : "8px 0",
                cursor: "pointer",
              }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "5%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "25px",
                      color: "#262626",
                      borderRight: "3px #262626 solid",
                      padding: "5px 0",
                      cursor: "pointer",
                    }
                  : {
                      width: "25px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      padding: "5px 0",
                      cursor: "pointer",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
      {
        breakpoint: 768,
        settings: {
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "3%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "20px",
                      color: "#262626",
                      borderRight: "3px #262626 solid",
                      padding: "3px 0",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
                  : {
                      width: "20px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      padding: "3px 0",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
      {
        breakpoint: 576,
        settings: {
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "2%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "15px",
                      color: "#262626",
                      borderRight: "3px #262626 solid",
                      padding: "3px 0",
                      cursor: "pointer",
                      fontSize: "10px",
                    }
                  : {
                      width: "15px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      padding: "3px 0",
                      cursor: "pointer",
                      fontSize: "10px",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  const slides = [ 
    {
      imgSrc: vintone,
      text: "Quality Redefined",
      Subtext:
        "Vintara Collections is all about adding that delicate detail; small, but big enough to turn heads.",
      buttonLink: "/shop",
      buttonText: "Shop Now",
    },
    {
      imgSrc: bannertwo,
      text: "Quality Redefined",
      Subtext:
        "Vintara Collections is all about adding that delicate detail; small, but big enough to turn heads.",
      buttonLink: "/shop",
      buttonText: "Shop Now",
    },
    {
      imgSrc: bannerthree,
      text: "Quality Redefined",
      Subtext:
        "Vintara Collections is all about adding that delicate detail; small, but big enough to turn heads.",
      buttonLink: "/shop",
      buttonText: "Shop Now",
    },

    // Add more slides as needed
  ];

  return (
    <div className="w-full bg-white">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <CustomSlide key={index} {...slide} />
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
