import React, { useState, useEffect } from "react";

const BannerBottom = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const messages = ["elegant", "simple", "classy"];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-orange-500 text-white px-10 hidden md:flex flex-col sm:flex-row justify-around items-center py-0">
      <div className="flex items-center">
        <span className="text-lg font-bold">
          Experience Vintara Africa's elegance
        </span>
      </div>
      <div className="relative h-8 w-40 overflow-hidden">
        {messages.map((message, index) => (
          <span
            key={index}
            className={`absolute left-0 right-0 text-center transition-opacity duration-1000 ease-in-out text-2xl font-black ${
              index === activeIndex ? "opacity-100 z-10" : "opacity-0 z-0"
            }`}
          >
            {message}
          </span>
        ))}
      </div>
      <div className="mt-4 sm:mt-0 flex items-center space-x-4 bg-blue-600 p-1 pl-8 pr-8">
        <div className="flex items-center flex-col">
          <span className="text-sm">CALL OR WHATSAPP</span>
          <span className="text-xl font-bold ml-2">0713 974 672</span>
        </div>
      </div>
    </div>
  );
};

export default BannerBottom;
