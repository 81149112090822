import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import useSession from "../../../context/useSession";
import { getTextColorFromHex } from "../../../utils/getTextColor";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [sizeModalOpen, setSizeModalOpen] = useState(false);
  const [colorName, setColorName] = useState("");
  const [colorTitle, setColorTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [sizeName, setSizeName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { authToken } = useSession();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [colorsResponse, categoriesResponse, sizesResponse] =
        await Promise.all([fetchColors(), fetchCategories(), fetchSizes()]);

      setColors(colorsResponse.data.data);
      setCategories(categoriesResponse.data.data);
      setSizes(sizesResponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchColors = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const fetchCategories = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const fetchSizes = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/sizes`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const handleRegisterColor = async () => {
    if (!colorTitle) {
      alert("Please provide a color title");
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
        { name: colorName, title:colorTitle },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setColorModalOpen(false);
      setColorName("");
      fetchData();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Color registered successfully.",
        confirmButtonText: "Okay",
        confirmButtonColor: "#3085d6",
      });
    } catch (error) {
      console.error("Error registering color:", error);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to register color. Please provide a color.",
        confirmButtonText: "Okay",
        confirmButtonColor: "#d33",
      });
    }
  };

  const handleRegisterCategory = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
        { name: categoryName },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setCategoryModalOpen(false);
      setCategoryName("");
      fetchData();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Category registered successfully.",
        confirmButtonText: "Okay",
        confirmButtonColor: "#3085d6",
      });
    } catch (error) {
      console.error("Error registering category:", error);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to register category. Please provide a category.",
        confirmButtonText: "Okay",
        confirmButtonColor: "#d33",
      });
    }
  };

  const handleRegisterSize = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/sizes`,
        { name: sizeName },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setSizeModalOpen(false);
      setSizeName("");
      fetchData();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Size registered successfully.",
        confirmButtonText: "Okay",
        confirmButtonColor: "#3085d6",
      });
    } catch (error) {
      console.error("Error registering size:", error);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to register size. Please provide a size.",
        confirmButtonText: "Okay",
        confirmButtonColor: "#d33",
      });
    }
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/reset_password`,
        {
          newEmail: username,
          newPassword: password,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Password reset successful.",
      });
      setUsername("");
      setPassword("");
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to reset password. Please enter your email and new password.",
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 p-8">
      {/* User Settings */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold mb-6">User Settings</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700 text-sm font-medium">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-medium">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <button
            onClick={handleSaveSettings}
            className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 transition duration-300"
          >
            Save Settings
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="bg-white shadow-lg rounded-lg mb-6">
        <h2 className="text-2xl font-bold mb-2 p-2">Product Settings</h2>
        <div className="flex border-b border-gray-300">
          <button
            onClick={() => setActiveTab(0)}
            className={`flex-1 py-3 text-lg font-semibold ${
              activeTab === 0
                ? "border-b-2 border-blue-600 text-blue-600"
                : "text-gray-600"
            }`}
          >
            Colors
          </button>
          <button
            onClick={() => setActiveTab(1)}
            className={`flex-1 py-3 text-lg font-semibold ${
              activeTab === 1
                ? "border-b-2 border-blue-600 text-blue-600"
                : "text-gray-600"
            }`}
          >
            Categories
          </button>
          <button
            onClick={() => setActiveTab(2)}
            className={`flex-1 py-3 text-lg font-semibold ${
              activeTab === 2
                ? "border-b-2 border-blue-600 text-blue-600"
                : "text-gray-600"
            }`}
          >
            Sizes
          </button>
        </div>
      </div>

      {/* Tab Content */}
      <div className="bg-white shadow-lg rounded-lg p-6">
        {activeTab === 0 && (
          <div>
            <button
              onClick={() => setColorModalOpen(true)}
              className="mb-4 bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 transition duration-300"
            >
              Register New Color
            </button>
            <div className="grid grid-cols-2 gap-4">
              {colors.map((color) => (
                <div
                  key={color.id}
                  className="p-4 rounded-md shadow-md border border-gray-200"
                  style={{ backgroundColor: color.name }}
                >
                  <p
                    className="text-lg font-semibold"
                    style={{
                      color:getTextColorFromHex(color.name)
                    }}
                  >
                    {color?.title || "N/A"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 1 && (
          <div>
            <button
              onClick={() => setCategoryModalOpen(true)}
              className="mb-4 bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 transition duration-300"
            >
              Register New Category
            </button>
            <div className="grid grid-cols-2 gap-4">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="bg-gray-50 p-4 rounded-md shadow-md border border-gray-200"
                >
                  <p className="text-lg font-semibold text-gray-800">
                    {category.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div>
            <button
              onClick={() => setSizeModalOpen(true)}
              className="mb-4 bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 transition duration-300"
            >
              Register New Size
            </button>
            <div className="grid grid-cols-2 gap-4">
              {sizes.map((size) => (
                <div
                  key={size.id}
                  className="bg-gray-50 p-4 rounded-md shadow-md border border-gray-200"
                >
                  <p className="text-lg font-semibold text-gray-800">
                    {size.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Color Modal */}
      {colorModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={() => setColorModalOpen(false)}
        >
          <div
            className="bg-blue-600 p-6 rounded-lg shadow-lg max-w-lg max-h-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg  text-white font-semibold mb-4">
              Register New Color
            </h3>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col space-y-2">
                <label className="text-white">Choose Color</label>
                <input
                  type="color"
                  value={colorName}
                  onChange={(e) => setColorName(e.target.value)}
                  placeholder="Enter color"
                  className="w-full h-[50px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-white">Choose Color Title</label>
              <input
                type="text"
                value={colorTitle}
                onChange={(e) => setColorTitle(e.target.value)}
                placeholder="Enter color Title"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
              </div>
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={handleRegisterColor}
                className="bg-orange-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-orange-600 transition duration-300"
              >
                Register
              </button>
              <button
                onClick={() => setColorModalOpen(false)}
                className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md shadow-sm hover:bg-gray-300 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Category Modal */}
      {categoryModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={() => setCategoryModalOpen(false)}
        >
          <div
            className="bg-blue-600 p-6 rounded-lg shadow-lg max-w-lg max-h-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg text-white font-semibold mb-4">
              Register New Category
            </h3>
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={handleRegisterCategory}
                className="bg-orange-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-orange-600 transition duration-300"
              >
                Register
              </button>
              <button
                onClick={() => setCategoryModalOpen(false)}
                className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md shadow-sm hover:bg-gray-300 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Size Modal */}
      {sizeModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={() => setSizeModalOpen(false)}
        >
          <div
            className="bg-blue-600 p-6 rounded-lg shadow-lg max-w-lg max-h-lg w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg text-white font-semibold mb-4">
              Register New Size
            </h3>
            <input
              type="text"
              value={sizeName}
              onChange={(e) => setSizeName(e.target.value)}
              placeholder="Enter size name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={handleRegisterSize}
                className="bg-orange-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-orange-600 transition duration-300"
              >
                Register
              </button>
              <button
                onClick={() => setSizeModalOpen(false)}
                className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md shadow-sm hover:bg-gray-300 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
