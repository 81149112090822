import React from "react";
import { Link } from "react-router-dom";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";
import bannerimage from "../../../assets/images/banner/vintone.png";

const YearProduct = () => {
  return (
    <a href="/shop">
      <div className="w-full h-80 mb-20 bg-slate-200 md:bg-slate-200 hover:bg-slate-300 duration-300 relative font-titleFont p-4">
        <img
          className="hidden md:inline-block"
          style={{
            width: "50%",
            height: "300px",
            objectFit: "contain",
            objectPosition: "center",
          }}
          src={bannerimage}
        />
        <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
          <h1 className="text-3xl font-semibold text-primeColor">
            Featured products
          </h1>
          <p className="text-base font-normal text-primeColor max-w-[600px] mr-4">
            Click Shop Now to view our best selling products, new products and
            latest offers.
          </p>
          <a href={"/shop"}>
            <ShopNow />
          </a>
        </div>
      </div>
    </a>
  );
};

export default YearProduct;
