import React from "react";
import { AiOutlineCopyright } from "react-icons/ai";

const FooterBottom = () => {
  return (
    <div className="w-full bg-[#F5F5F3] group">
      <div className="max-w-container flex flex-col items-center space-y-5 mx-auto border-t-[1px] pt-10 pb-20">
        <p className="text-titleFont font-normal text-center flex md:items-center justify-center text-lightText duration-200 text-sm">
          <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center hidden md:inline-flex">
            <AiOutlineCopyright />
          </span>
          Copyright 2024 | Vintara Collections | All Rights Reserved
        </p>
        <span className="hidden sm:flex ml-1 text-gray-400 items-center space-x-1">
          <p className="text-sm">Made with ❤️ by</p>
          <a
            className="text-sm  font-medium hover:text-gray-600"
            href="https://alphanex.co.ke"
            target="_blank"
            rel="noopener noreferrer"
          >
            Alphanex
          </a>
        </span>
      </div>
    </div>
  );
};

export default FooterBottom;
