import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaShoppingCart,
  FaHourglassHalf,
  FaCheckCircle,
  FaSearch,
} from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import useSession from "../../../context/useSession";
import { motion } from "framer-motion";

const Orders = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { authToken } = useSession();

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/orders`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setOrders(response.data.orders);
        setTotalAmount(response.data.totalAmount);
        setOrderCount(response.data.orderCount);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [authToken]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleDetails = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const filteredOrders = orders.filter(
    (order) =>
      order.id.toString().includes(searchTerm) ||
      order.user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get current orders for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <motion.div
      className="p-6 bg-gray-50 min-h-screen"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      {/* Analytics Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-between">
          <div className="flex items-center">
            <FaShoppingCart className="text-5xl text-blue-600 mr-4" />
            <div>
              <p className="text-gray-700 text-lg font-semibold">
                Total Orders
              </p>
              <p className="text-2xl font-bold text-blue-600">{orderCount}</p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-between">
          <div className="flex items-center">
            <FaCheckCircle className="text-5xl text-green-500 mr-4" />
            <div>
              <p className="text-gray-700 text-lg font-semibold">Total Sales</p>
              <p className="text-2xl font-bold text-green-500">
                KES {totalAmount}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Search Bar */}
      <div className="mb-6 flex items-center">
        <div className="relative w-full md:w-80">
          <input
            type="text"
            placeholder="Search orders..."
            className="w-full p-3 pl-12 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={handleSearch}
          />
          <FaSearch className="absolute left-4 top-3 text-gray-500" />
        </div>
      </div>

      {/* Orders Table */}
      <div className="bg-white shadow-lg rounded-lg overflow-x-auto">
        {loading ? (
          <p className="text-center py-6 text-gray-500">Loading...</p>
        ) : (
          <>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Customer Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentOrders.map((order) => (
                  <React.Fragment key={order.id}>
                    <motion.tr
                      className="hover:bg-gray-50 cursor-pointer"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <td className="px-6 py-4 text-sm font-medium text-gray-900">
                        {order.id}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {order.user.fullName}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {new Date(order.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        KES {order.totalAmount}
                      </td>
                      <td className="px-6 py-4 text-sm">
                        <span
                          className={`px-2 py-1 rounded-full text-white ${
                            order.status === "cancelled"
                              ? "bg-red-500"
                              : order.status === "pending"
                              ? "bg-orange-500"
                              : "bg-green-500"
                          }`}
                        >
                          {order.status.charAt(0).toUpperCase() +
                            order.status.slice(1)}
                        </span>
                      </td>

                      <td className="px-6 py-4 text-sm font-medium">
                        <button
                          onClick={() => handleToggleDetails(order.id)}
                          className="text-blue-600 hover:text-blue-800 focus:outline-none"
                        >
                          {expandedOrderId === order.id ? (
                            <IoMdArrowDropdown className="inline-block" />
                          ) : (
                            <IoMdArrowDropright className="inline-block" />
                          )}
                          {expandedOrderId === order.id
                            ? " Hide Details"
                            : " Show Details"}
                        </button>
                      </td>
                    </motion.tr>
                    {expandedOrderId === order.id && (
                      <motion.tr
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <td
                          colSpan="5"
                          className="px-6 py-4 bg-gray-50 text-sm text-gray-600"
                        >
                          <div className="space-y-4">
                            <h3 className="text-lg font-semibold mb-2 border-b-2 border-gray-200 pb-2">
                              Order Details
                            </h3>
                            <table className="w-full border border-gray-300 mb-4">
                              <thead>
                                <tr className="bg-gray-100">
                                  <th className="border px-4 py-2 text-left">
                                    Product
                                  </th>
                                  <th className="border px-4 py-2 text-left">
                                    Color
                                  </th>
                                  <th className="border px-4 py-2 text-left">
                                    Size
                                  </th>
                                  <th className="border px-4 py-2 text-left">
                                    Quantity
                                  </th>
                                  <th className="border px-4 py-2 text-left">
                                    Price Per Unit
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.orderProducts.map((product) => (
                                  <tr key={product.id}>
                                    <td className="border px-4 py-2">
                                      {product.productName}
                                    </td>
                                    <td className="border px-4 py-2 flex items-center gap-2">
                                      <span
                                        className="inline-block w-4 h-4 rounded-full ml-2"
                                        style={{
                                          backgroundColor: product.colorName,
                                        }}
                                      ></span>
                                      {product.colorName}
                                    </td>

                                    <td className="border px-4 py-2">
                                      {product.sizeName}
                                    </td>
                                    <td className="border px-4 py-2">
                                      {product.orderCount}
                                    </td>
                                    <td className="border px-4 py-2">
                                      KES {product.pricePerCount}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="flex flex-col items-start justify-start gap-5 py-2 px-2 md:flex-row md:items-center md:justify-between md:py-5 md:px-4">
                              <div className="space-y-2">
                                <h3 className="text-lg font-semibold mb-2 border-b-2 border-gray-200 pb-2">
                                  Customer Contact Details
                                </h3>
                                <p>
                                  <strong className="text-gray-800">
                                    Name:
                                  </strong>{" "}
                                  {order.user.fullName}
                                </p>
                                <p>
                                  <strong className="text-gray-800">
                                    Mobile:
                                  </strong>{" "}
                                  {order.user.phoneNumber}
                                </p>
                                <p>
                                  <strong className="text-gray-800">
                                    Email:
                                  </strong>{" "}
                                  {order.user.email}
                                </p>
                              </div>

                              <div className="space-y-2">
                                <h3 className="text-lg font-semibold mb-2 border-b-2 border-gray-200 pb-2">
                                  Delivery Details
                                </h3>
                                {order.deliveryDetails.length > 0 ? (
                                  <>
                                    <p>
                                      <strong className="text-gray-800">
                                        Receiver:
                                      </strong>{" "}
                                      {order.deliveryDetails[0].receiverName}
                                    </p>
                                    <p>
                                      <strong className="text-gray-800">
                                        Mobile:
                                      </strong>{" "}
                                      {order.deliveryDetails[0].receiverMobile}
                                    </p>
                                    <p>
                                      <strong className="text-gray-800">
                                        Email:
                                      </strong>{" "}
                                      {order.deliveryDetails[0].receiverEmail}
                                    </p>
                                    <p>
                                      <strong className="text-gray-800">
                                        Address:
                                      </strong>{" "}
                                      {order.deliveryDetails[0].address}
                                    </p>
                                  </>
                                ) : (
                                  <p>No delivery details available.</p>
                                )}
                              </div>

                              <div className="space-y-2">
                                <h3 className="text-lg font-semibold mb-2 border-b-2 border-gray-200 pb-2">
                                  Mpesa Details
                                </h3>
                                {order.mpesaPayments.length > 0 ? (
                                  <>
                                    <p>
                                      <strong className="text-gray-800">
                                        Amount:
                                      </strong>{" "}
                                      {order.mpesaPayments[0].amount}
                                    </p>
                                    <p>
                                      <strong className="text-gray-800">
                                        Status:
                                      </strong>{" "}
                                      <strong
                                        className={`px-2 py-1 rounded-full ${
                                          order.mpesaPayments[0]
                                            .transactionStatus === "paid"
                                            ? "bg-green-500 text-white"
                                            : order.mpesaPayments[0]
                                                .transactionStatus ===
                                              "cancelled"
                                            ? "bg-red-500 text-white"
                                            : "bg-gray-200 text-gray-800"
                                        }`}
                                      >
                                        {
                                          order.mpesaPayments[0]
                                            .transactionStatus
                                        }
                                      </strong>
                                    </p>

                                    <p>
                                      <strong className="text-gray-800">
                                        Reference Code:
                                      </strong>{" "}
                                      {order.mpesaPayments[0].ReferenceCode}
                                    </p>
                                  </>
                                ) : (
                                  <p>No Mpesa payments available.</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </motion.tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="flex justify-between items-center py-4 px-4">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50"
              >
                Previous
              </button>
              <div>
                Page {currentPage} of{" "}
                {Math.ceil(filteredOrders.length / ordersPerPage)}
              </div>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredOrders.length / ordersPerPage)
                }
                className="bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default Orders;
