import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMaxPrice, setMinPrice } from "../../../../redux/orebiSlice";
import NavTitle from "./NavTitle";

const PriceFilter = () => {
  const dispatch = useDispatch();
  const minPrice = useSelector((state) => state.orebiReducer.minPrice);
  const maxPrice = useSelector((state) => state.orebiReducer.maxPrice);

  const handleMinPriceChange = (e) => {
    dispatch(setMinPrice(parseFloat(e.target.value) || 0));
  };

  const handleMaxPriceChange = (e) => {
    dispatch(setMaxPrice(parseFloat(e.target.value) || 10000));
  };

  const minRange = 0;
  const maxRange = 10000;

  const progressWidth = ((maxPrice - minPrice) / (maxRange - minRange)) * 100;

  return (
    <div className="bg-white mb-8 relative overflow-hidden">
      <NavTitle title="Shop by price" icons={true} />
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <label
            htmlFor="minPrice"
            className="text-[10px] lg:text-xs font-medium text-gray-400"
          >
            Min Price:
          </label>
          <label
            htmlFor="maxPrice"
            className="text-[10px] lg:text-xs font-medium text-gray-400"
          >
            Max Price:
          </label>
        </div>
        <div className="flex items-center gap-4 mb-4">
          <input
            type="number"
            id="minPrice"
            value={minPrice || ""}
            onChange={handleMinPriceChange}
            min={minRange}
            className="w-full max-w-xs p-1 border border-gray-300 rounded-lg shadow-md outline-none text-[10px] lg:text-xs "
            placeholder="Min Price"
          />
          <input
            type="number"
            id="maxPrice"
            value={maxPrice || ""}
            onChange={handleMaxPriceChange}
            min={minRange}
            className="w-full max-w-xs p-1 border border-gray-300 rounded-lg shadow-md outline-none text-[10px] lg:text-xs "
            placeholder="Max Price"
          />
        </div>
        <div className="relative w-full h-6 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-orange-200 rounded-full transition-all duration-300"
            style={{ width: `${progressWidth}%` }}
          ></div>
          <div className="absolute inset-0 flex items-center justify-between px-2 text-[10px] lg:text-xs text-gray-700">
            <span>KES {minPrice || minRange}</span>
            <span>KES {maxPrice || maxRange}</span>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-white via-transparent to-transparent opacity-50"></div>
    </div>
  );
};

export default PriceFilter;
