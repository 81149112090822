import React from "react";
import { Link } from "react-router-dom";

import ShopNow from "../../designLayouts/buttons/ShopNow";
import laptopbags from "../../../assets/images/sales/brown_laptop_bag_without_bg.png";
import travelling from "../../../assets/images/sales/vintara_black_without_bg.png";
import tote from "../../../assets/images/sales/brown_small_bag_without_bg.png"; 

const Sale = () => {
  return (
    <div className="py-20 flex flex-col md:flex-row items-stretch justify-between gap-4 lg:gap-10">
      {/* Left Component */}
      <div className="bg-orange-300 w-full md:w-2/3 lg:w-1/2 flex flex-col justify-between text-black p-4 rounded">
        <div className="flex-grow flex flex-col justify-center">
          <img
            src={laptopbags}
            className="h-64 md:h-96 lg:h-96 w-full object-contain mb-4"
            alt="Laptop Bags"
          />
          <div className="text-left w-full">
            <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-4">
              Laptop Bags
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl mb-6">
              Easily carry your laptop to job.
              {/* <span className="text-4xl md:text-5xl lg:text-5xl font-bold">
              </span>{" "} */}
            </p>
            <Link to="/shop" className="inline-block">
              <ShopNow />
            </Link>
          </div>
        </div>
      </div>

      {/* Right Component */}
      <div className="w-full md:w-2/3 lg:w-1/2 flex flex-col gap-4">
        <div className="bg-slate-300 flex-1 flex flex-col justify-between p-4 rounded">
          <div className="w-full flex-grow flex flex-col justify-center">
            <img
              src={travelling}
              className="h-64 md:72 lg:72 w-full object-contain mb-4"
              alt="Travelling Bags"
            />
            <div className="text-left w-full">
              <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold mb-4">
                Travelling Bags
              </h2>
              <Link to="/shop" className="inline-block">
                <ShopNow />
              </Link>
            </div>
          </div>
        </div>

        <div className="bg-slate-300 flex-1 flex flex-col justify-between p-4 rounded">
          <div className="w-full flex-grow flex flex-col justify-center">
            <img
              src={tote}
              className="h-64 md:72 lg:72 w-full object-contain mb-4"
              alt="Tote Bags"
            />
            <div className="text-left w-full">
              <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold mb-4">
                Small Laptop bags
              </h2>
              <Link to="/shop" className="inline-block">
                <ShopNow />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sale;
