import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { MdSwitchAccount, MdCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { CgMenuGridO } from "react-icons/cg";
import Tooltip from "@mui/material/Tooltip";
import { CartContext } from "../../context/cart/CartProvider ";

const SpecialCase = () => {
  const { cartItemCount } = useContext(CartContext);

  const handleCall = () => {
    window.open("tel:+254713974672", "_self");
  };

  return (
    <div className="fixed top-3/4 sm:top-3/4 md:top-3/4 lg:top-3/4 right-2 z-20 flex flex-col items-center">
      <div className="relative group">
        <div className="absolute bottom-full mb-2 flex flex-col items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <Tooltip title="Cart" placement="left" arrow>
            <a href="/cart">
              <div className="bg-orange-500 w-13 h-[45px] rounded-full flex flex-col gap-1 mb-2  text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative">
                <div className="flex justify-center items-center">
                  <RiShoppingCart2Fill className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200 text-white" />
                  <RiShoppingCart2Fill className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200 text-white" />
                </div>
                <p className="absolute top-1 right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                  {cartItemCount}
                </p>
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Call" placement="left" arrow>
            <div
              onClick={handleCall}
              className="bg-blue-500 w-13 h-[45px] rounded-full flex flex-col gap-1 mb-2  text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative"
            >
              <div className="flex justify-center items-center">
                <MdCall className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200 text-white" />
                <MdCall className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200 text-white" />
              </div>
            </div>
          </Tooltip>
          <Tooltip title="WhatsApp" placement="left" arrow>
            <a
              href="https://wa.me/254713974672"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 w-13 h-[45px] rounded-full flex flex-col gap-1 mb-2  text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative"
            >
              <div className="flex justify-center items-center">
                <FaWhatsapp className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200 text-white" />
                <FaWhatsapp className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200 text-white" />
              </div>
            </a>
          </Tooltip>
        </div>

        <div className="bg-black w-14 h-14 rounded-full flex items-center justify-center text-white shadow-testShadow cursor-pointer transition-transform duration-300 transform group-hover:rotate-180">
          <CgMenuGridO className="text-2xl" />
        </div>
      </div>
    </div>
  );
};

export default SpecialCase;
