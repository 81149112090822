import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import Select from "react-select";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSession from "../../context/useSession";

const UpdatePriceModal = ({ open, onClose, product, onPriceUpdate }) => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [newPrice, setNewPrice] = useState("");
  const [colorCounts, setColorCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const { authToken } = useSession();

  const sizes = product?.sizes || [];

  const handleSizeChange = (selectedOption) => {
    setSelectedSize(selectedOption);
    setNewPrice(""); // Reset new price when size changes
    setColorCounts({}); // Reset color counts when size changes
  };

  const handleCountChange = (colorId, value) => {
    setColorCounts({
      ...colorCounts,
      [colorId]: value,
    });
  };

  const handleSubmit = async () => {
    // if (!selectedSize || !newPrice) {
    //   toast.error("Please fill in all fields.");
    //   return;
    // }

    setLoading(true);

    try {
      const sizeId = selectedSize.value;
      const newCount = Object.entries(colorCounts).reduce(
        (acc, [colorId, count]) => acc + parseInt(count, 10),
        0
      );

      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/size_price_update`,
        {
          sizeId,
          newPrice: parseFloat(newPrice),
          newCount,
          colorCounts,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast.success("Size and color counts updated successfully");
      onPriceUpdate();
      onClose();
    } catch (error) {
      console.error("Error updating size and counts:", error);
      toast.error("Failed to update size and counts");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            bgcolor: "#2563eb",
            color: "white",
            textAlign: "center",
            fontSize: "1.0rem",
            py: 1,
          }}
        >
          Product Update
        </DialogTitle>
        <DialogContent
          sx={{
            p: 6,
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div className="mb-6 mt-4 w-full">
            <label className="block text-sm font-medium text-gray-700">
              Select Size
            </label>
            <Select
              value={selectedSize}
              onChange={handleSizeChange}
              options={sizes.map((size) => ({
                value: size.id,
                label: `${size.sizeName.name} - current price: KES ${size.price}`,
              }))}
              placeholder="Select a size..."
              className="mt-2"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: "#D1D5DB",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#0E2A47",
                  },
                }),
              }}
            />
          </div>
          {selectedSize && (
            <>
              <div className="mb-6 mt-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  New Price
                </label>
                <TextField
                  type="number"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                  placeholder="Enter new price"
                  variant="outlined"
                  size="small"
                  className="mt-2 w-full"
                />
              </div>
              <div className="w-full mt-4">
                {sizes
                  .find((size) => size.id === selectedSize.value)
                  ?.colors.map((color) => (
                    <div key={color.id} className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center gap-2">
                        <span
                          style={{
                            backgroundColor: color.colorName.name,
                          }}
                          className="w-4 h-4 rounded-full"
                        ></span>
                        {color.colorName.name}
                      </label>
                      <TextField
                        type="number"
                        value={colorCounts[color.id] || ""}
                        onChange={(e) =>
                          handleCountChange(color.id, e.target.value)
                        }
                        placeholder={`Enter new count for ${color.colorName.name}`}
                        variant="outlined"
                        size="small"
                        className="mt-2 w-full"
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3, bgcolor: "#f5f5f5" }}>
          <Button
            onClick={onClose}
            sx={{
              fontWeight: "bold",
              backgroundColor: "#dc2626",
              color: "white",
              width: "100%",
              "&:hover": {
                backgroundColor: "#b91c1c",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              fontWeight: "bold",
              backgroundColor: "#2563eb",
              color: "white",
              width: "100%",
              "&:hover": {
                backgroundColor: "#1d4ed8",
              },
            }}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default UpdatePriceModal;
