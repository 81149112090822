import React from "react";

const FeedbackAnchor = () => {
  return (
    <div className="fixed top-60 md:top-72 right-0 z-20 flex items-center">
      <a
        href="/feedback"
        className="transform -rotate-90 translate-x-1/3 bg-blue-700 text-white p-1 px-2"
      >
        <span>Feedback</span>
      </a>
    </div>
  );
};

export default FeedbackAnchor;
