import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import Logo from "../../../assets/LOGO/logo.png";
import Flex from "../../designLayouts/Flex";
import Category from "../../pageProps/shopPage/shopBy/Category";
import ColorFilter from "../../pageProps/shopPage/shopBy/ColorFilter";
import PriceFilter from "../../pageProps/shopPage/shopBy/PriceFilter";
import useSession from "../../../context/useSession";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [openSection, setOpenSection] = useState(null);
  const location = useLocation();
  const { authToken, logout, user } = useSession();

  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  const handleToggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/">
            <div>
              <a href="#" className="font-bold text-2xl sm:text-3xl flex gap-2">
                <img src={Logo} alt="Logo" className="w-44" />
              </a>
            </div>
          </Link>
          <div>
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
                state={{ data: location.pathname.split("/")[1] }}
              >
                <li className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0">
                  <a href="/shop">Shop</a>
                </li>

                {authToken && user === false && (
                  <li className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0">
                    <a href="/shop">Admin</a>
                  </li>
                )}

                {authToken ? (
                  <a
                    className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                    href="/"
                    onClick={logout}
                  >
                    <li>Logout</li>
                  </a>
                ) : (
                  <>
                    <a
                      className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626]"
                      href="/signin"
                    >
                      <li>Login</li>
                    </a>
                    <a
                      className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626]"
                      href="/signup"
                    >
                      <li>Signup</li>
                    </a>
                  </>
                )}
              </motion.ul>
            )}
            <HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-white p-6">
                    {/* Company Logo */}
                    <div className="flex justify-center items-center mt-4 mb-6">
                      <img src={Logo} alt="Company Logo" className="w-44" />
                    </div>
                    <div className="flex flex-col justify-center mt-8 overflow-y-auto max-h-[50vh] py-8">
                      <div className="mt-4">
                        <h1
                          onClick={() => handleToggleSection("category")}
                          className="flex justify-between  text-base text-black  cursor-pointer items-center font-titleFont mb-2"
                        >
                          Shop by Category
                          <span className="text-lg">
                            {openSection === "category" ? "-" : "+"}
                          </span>
                        </h1>
                        {openSection === "category" && (
                          <motion.ul
                            initial={{ y: 15, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.4 }}
                            className="text-sm flex flex-col gap-1"
                          >
                            <Category title={false} />
                          </motion.ul>
                        )}
                      </div>
                      <div className="mt-4">
                        <h1
                          onClick={() => handleToggleSection("color")}
                          className="flex justify-between text-black text-base cursor-pointer items-center font-titleFont mb-2"
                        >
                          Shop by Color
                          <span className="text-lg">
                            {openSection === "color" ? "-" : "+"}
                          </span>
                        </h1>
                        {openSection === "color" && (
                          <motion.ul
                            initial={{ y: 15, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.4 }}
                            className="text-sm flex flex-col gap-1"
                          >
                            <ColorFilter />
                          </motion.ul>
                        )}
                      </div>
                      <div className="mt-4">
                        <h1
                          onClick={() => handleToggleSection("price")}
                          className="flex justify-between text-black text-base cursor-pointer items-center font-titleFont mb-2"
                        >
                          Shop by Price
                          <span className="text-lg">
                            {openSection === "price" ? "-" : "+"}
                          </span>
                        </h1>
                        {openSection === "price" && (
                          <motion.ul
                            initial={{ y: 15, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.4 }}
                            className="text-sm flex flex-col gap-1"
                          >
                            <PriceFilter />
                          </motion.ul>
                        )}
                      </div>
                    </div>

                    <div className="absolute bottom-10 w-[80%] flex justify-center gap-2 items-center">
                      {authToken ? (
                        <a
                          className="w-32 text-center text-white bg-orange-500 p-2 rounded-lg"
                          href="/"
                          onClick={logout}
                        >
                          Logout
                        </a>
                      ) : (
                        <>
                          <a
                            className="w-32 text-center text-white bg-orange-500 p-2 rounded-lg"
                            href="/signin"
                          >
                            Login
                          </a>
                          <a
                            className="w-32 text-center text-white bg-blue-600 p-2 rounded-lg"
                            href="/signup"
                          >
                            Signup
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
          </div>
        </Flex>
      </nav>
    </div>
  );
};

export default Header;
