import React, { useState, useEffect } from "react";
import {
  FaDollarSign,
  FaCalendarWeek,
  FaBox,
  FaUsers,
  FaWarehouse,
  FaSearch,
  FaCubes,
} from "react-icons/fa";
import useSession from "../../../context/useSession";
import axios from "axios";
import moment from "moment";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Pie, Line } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale
);

const ProductsAdmin = () => {
  const { authToken, user } = useSession();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_products`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products);
        console.log(response.data.products);
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    fetchProducts();
  }, [authToken]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Stock Analysis Data
  const totalStock = products.reduce(
    (sum, product) =>
      sum + product.sizes.reduce((sizeSum, size) => sizeSum + size.count, 0),
    0
  );

  const totalSoldStock = products.reduce(
    (sum, product) =>
      sum +
      product.sizes.reduce(
        (sizeSum, size) => sizeSum + (size.sold ? size.sold : 0),
        0
      ),
    0
  );

  const totalActiveStock = totalStock - totalSoldStock;

  const totalStockValue = products.reduce(
    (sum, product) =>
      sum +
      product.sizes.reduce(
        (sizeSum, size) => sizeSum + size.price * size.count,
        0
      ),
    0
  );

  // Pie Chart Data
  const pieData = {
    labels: ["Sold Stock", "Active Stock"],
    datasets: [
      {
        data: [totalSoldStock, totalActiveStock],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  // Line Chart Data (Example with dates and stock levels)
  const lineLabels = products.map((product) =>
    moment(product.createdAt).format("MMM D")
  );
  const lineData = {
    labels: lineLabels,
    datasets: [
      {
        label: "Stock Levels",
        data: products.map((product) =>
          product.sizes.reduce((sizeSum, size) => sizeSum + size.count, 0)
        ),
        fill: false,
        backgroundColor: "#36A2EB",
        borderColor: "#36A2EB",
      },
    ],
  };

  const lineOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const pieOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div className="container mx-auto px-0 md:px-4 pb-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8 pt-5">
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaBox className="text-4xl text-blue-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">
              Total Products
            </h3>
            <p className="text-base font-normal text-gray-900">
              {products.length}
            </p>
          </div>
        </div>

        {/* Total Stock */}
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaCubes className="text-4xl text-green-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Total Stock</h3>
            <p className="text-base font-normal text-gray-900">{totalStock}</p>
          </div>
        </div>

        {/* Total Sold Stock */}
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaWarehouse className="text-4xl text-purple-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">
              Total Sold Stock
            </h3>
            <p className="text-base font-normal text-gray-900">
              {totalSoldStock}
            </p>
          </div>
        </div>

        {/* Total Stock Value */}
        <div className="bg-slate-100 p-6 rounded-lg shadow-lg flex items-center justify-start space-x-5 py-8">
          <FaDollarSign className="text-4xl text-orange-600" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">
              Total Stock Value
            </h3>
            <p className="text-base font-normal text-gray-900">
              KES {totalStockValue.toFixed(0)}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white shadow rounded-lg p-4 h-80">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Stock Sold vs Active Stock
          </h3>
          <Pie data={pieData} options={pieOptions} />
        </div>

        <div className="bg-white shadow rounded-lg p-4 h-80">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Stock Levels Over Time
          </h3>
          <Line data={lineData} options={lineOptions} />
        </div>
      </div>

      <div className="flex items-end justify-end mb-4 mt-2 mr-5">
        <a
          href="/admin/register_item"
          className="bg-orange-600 text-white text-bold rounded p-2"
        >
          Add product
        </a>
      </div>

      <div className="mb-4 flex items-center">
        <div className="relative w-full md:w-72 max-w-d shadow-lg">
          <input
            type="text"
            placeholder="Search products..."
            className="w-full p-2 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-600 outline-1"
            value={searchTerm}
            onChange={handleSearch}
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>
      </div>

      {/* Products Table */}
      <div className="overflow-x-auto shadow-lg">
        <table className="min-w-full divide-y divide-gray-200 bg-[#F5F5F3] shadow-lg rounded-md">
          <thead className="bg-[#F5F5F3]">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Product Name
              </th>
              {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Category
              </th> */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Stock
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Created At
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-[#F5F5F3] divide-y divide-gray-300">
            {currentItems
              .filter((product) =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((product) => {
                // Get the first image from sizes
                const firstImage =
                  product.sizes?.[0]?.colors?.[0]?.images?.[0]?.imageurl;

                // Determine stock status based on sizes count
                const isInStock = product.sizes.some((size) => size.count > 0);

                // Calculate minimum and maximum prices
                const prices = product.sizes.map((size) => size.price);
                const minPrice = Math.min(...prices).toFixed(2);
                const maxPrice = Math.max(...prices).toFixed(2);

                return (
                  <tr key={product.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {firstImage ? (
                        <img
                          src={firstImage}
                          alt={product.name}
                          className="w-14 h-14 object-contain rounded-full shadow-md"
                        />
                      ) : (
                        <span>No Image</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {product.name}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.category.name}
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {minPrice === maxPrice
                        ? `KES ${minPrice}`
                        : `KES ${minPrice} - ${maxPrice}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="text-grey-50 bg-emerald-600 p-2 text-white rounded">
                        {isInStock ? "In Stock" : "Out of Stock"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {moment(product.createdAt).fromNow()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href={`/admin/product/${product.id}`}
                        className="text-white bg-blue-600 hover:bg-blue-800 p-2 rounded"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <nav aria-label="Page navigation">
          <ul className="inline-flex items-center -space-x-px">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index + 1}>
                <button
                  onClick={() => handleClick(index + 1)}
                  className={`px-4 py-2 text-sm font-medium ${
                    currentPage === index + 1
                      ? "bg-blue-600 text-white shadow-md transform scale-105"
                      : "bg-white text-gray-700 hover:bg-gray-100 hover:text-blue-600"
                  } border border-gray-300 rounded-full mx-1 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default ProductsAdmin;
