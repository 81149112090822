import React from "react";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineClose } from "react-icons/ai";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CartContext } from "../../context/cart/CartProvider ";
import useSession from "../../context/useSession";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddToCartModal({ show, handleClose, product, userId }) {
  const [selectedSize, setSelectedSize] = React.useState("");
  const [sizePrice, setSizePrice] = React.useState(0);
  const [colorQuantities, setColorQuantities] = React.useState({});
  const [error, setError] = React.useState("");
  const { authToken } = useSession();
  const { updateCart } = React.useContext(CartContext);

  const handleSizeChange = (event) => {
    const sizeId = event.target.value;
    setSelectedSize(sizeId);

    const size = product.sizes.find((size) => size.id === sizeId);
    const initialQuantities =
      size?.colors.reduce((acc, color) => {
        acc[color.id] = 0;
        return acc;
      }, {}) || {};
    setColorQuantities(initialQuantities);
    setSizePrice(size?.price || 0);
    setError("");
  };

  const handleQuantityChange = (colorId, delta) => {
    setColorQuantities((prevQuantities) => {
      const newQuantity = Math.max((prevQuantities[colorId] || 0) + delta, 0);
      const color = colors.find((color) => color.id === colorId);

      if (newQuantity > color.count) {
        toast.warning(
          `Only ${color.count} units left for ${color.colorName.name}.`
        );
        return prevQuantities;
      }

      return {
        ...prevQuantities,
        [colorId]: newQuantity,
      };
    });
  };

  const handleAddToCart = async () => {
    if (
      !selectedSize ||
      Object.values(colorQuantities).every((qty) => qty <= 0)
    ) {
      setError("Please select a size and specify quantities for each color.");
      toast.error(
        "Please select a size and specify quantities for each color."
      );
      return;
    }
    const size = product.sizes.find((size) => size.id === selectedSize);
    const sizePrice = size?.price || 0;
    const cartItems = Object.keys(colorQuantities)
      .filter((colorId) => colorQuantities[colorId] > 0)
      .map((colorId) => ({
        sizeId: selectedSize,
        colorId: parseInt(colorId, 10),
        price: sizePrice,
        count: colorQuantities[colorId],
      }));

    try {
      await updateCart({ cartItems, userId });
      handleClose();
      toast.success("Items added to cart successfully!");
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add items to cart.");
    }
  };

  const sizes = product.sizes || [];
  const colors = selectedSize
    ? sizes.find((size) => size.id === selectedSize)?.colors || []
    : [];

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="responsive-dialog-title"
      className="p-0 max-w-3xl mx-auto rounded-lg"
    >
      <div className="relative bg-white shadow-lg rounded-lg overflow-hidden">
        <DialogTitle
          id="responsive-dialog-title"
          className="text-2xl font-black text-black p-6 border-b flex items-center justify-between"
        >
          <span className="text-xl font-bold text-black">
            Please select a size
          </span>
          <IconButton onClick={handleClose}>
            <AiOutlineClose
              size={26}
              className="text-2xl font-black text-white bg-orange-600 border rounded-full p-0 shadow-md"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-6">
          <DialogContentText>
            <span className="mb-6 mt-6 text-slate-700">
              Please select the size, then specify the quantity for each color.
            </span>
          </DialogContentText>
          <FormControl variant="filled" fullWidth margin="normal">
            <InputLabel id="size-select-label">Size</InputLabel>
            <Select
              labelId="size-select-label"
              value={selectedSize}
              onChange={handleSizeChange}
              label="Size"
              className="bg-gray-100 border-gray-300"
              classes={{ select: "text-gray-700" }}
              sx={{ backgroundColor: "white" }}
            >
              <MenuItem value="" disabled>
                Select Size
              </MenuItem>
              {sizes.map((size) => (
                <MenuItem key={size.id} value={size.id}>
                  {size.sizeName.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedSize && (
            <div className="mt-4">
              {colors.map((color) => (
                <div key={color.id} className="flex items-center mb-4">
                  <div className="flex-1">
                    <div className="flex flex-col">
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {color.colorName.name}{" "}
                        <span
                          style={{
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            backgroundColor: color.colorName.name,
                            borderRadius: "50%",
                            marginLeft: "8px",
                            verticalAlign: "middle",
                          }}
                          className="shadow-md"
                        ></span>{" "}
                      </span>
                      <span className="font-semibold text-grey-600">
                        KES {sizePrice}
                      </span>
                      <span className="font-semibold text-orange-500">
                        {color.count} units left
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center ml-4">
                    <IconButton
                      onClick={() => handleQuantityChange(color.id, -1)}
                      disabled={colorQuantities[color.id] <= 0}
                    >
                      <AiOutlineMinus
                        size={20}
                        className="text-white bg-orange-500 border rounded-full p-0 shadow-md"
                      />
                    </IconButton>
                    <span className="mx-2 text-lg">
                      {colorQuantities[color.id] || 0}
                    </span>
                    <IconButton
                      onClick={() => handleQuantityChange(color.id, 1)}
                      disabled={colorQuantities[color.id] >= color.count}
                    >
                      <AiOutlinePlus
                        size={20}
                        className="text-white bg-orange-500 border rounded-full p-0 shadow-md"
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          )}
          {error && <p className="text-red-600 mt-4">{error}</p>}
        </DialogContent>
        <div className="flex items center justify-between p-6 border-t gap-2">
          <button className="bg-orange-50 border border-1 border-orange-500 p-1 text-orange-600 rounded-md px-2 w-full">
            Shop more
          </button>
          <button
            onClick={handleAddToCart}
            className="bg-orange-500 p-1 text-white rounded-md px-2 w-full"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </Dialog>
  );
}
