import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaShoppingCart,
  FaStar,
  FaComment,
} from "react-icons/fa";
import moment from "moment";
import { useParams } from "react-router-dom";
import useSession from "../../../context/useSession";
import { motion } from "framer-motion";

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken } = useSession();

  // Pagination states
  const [cartPage, setCartPage] = useState(1);
  const [orderPage, setOrderPage] = useState(1);
  const [ratingsPage, setRatingsPage] = useState(1);
  const [feedbackPage, setFeedbackPage] = useState(1);

  const itemsPerPage = 5;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/customers/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUser(response.data);
      } catch (err) {
        setError("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id, authToken]);

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <motion.div
          className="text-center text-gray-600"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Loading...
        </motion.div>
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <motion.div
          className="text-center text-red-600"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {error}
        </motion.div>
      </div>
    );

  if (!user)
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <motion.div
          className="text-center text-gray-600"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          User not found
        </motion.div>
      </div>
    );

  const {
    fullName,
    email,
    phoneNumber,
    createdAt,
    cart,
    order,
    ratings,
    feedback,
  } = user;

  // Calculate the number of pages
  const cartPageCount = Math.ceil((cart && cart.length) / itemsPerPage);
  const orderPageCount = Math.ceil((order && order.length) / itemsPerPage);
  const ratingsPageCount = Math.ceil((ratings && ratings.length) / itemsPerPage);
  const feedbackPageCount = Math.ceil((feedback && feedback.length) / itemsPerPage);

  // Get current items for each section
  const currentCartItems = cart.slice((cartPage - 1) * itemsPerPage, cartPage * itemsPerPage);
  const currentOrders = order.slice((orderPage - 1) * itemsPerPage, orderPage * itemsPerPage);
  const currentRatings = ratings.slice((ratingsPage - 1) * itemsPerPage, ratingsPage * itemsPerPage);
  const currentFeedback = feedback.slice((feedbackPage - 1) * itemsPerPage, feedbackPage * itemsPerPage);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {/* User Info Section */}
        <div className="flex items-center mb-6">
          <FaUser className="text-5xl text-blue-600 mr-6" />
          <div>
            <h2 className="text-3xl font-semibold text-gray-900">{fullName}</h2>
            <p className="text-gray-700 flex items-center mb-2">
              <FaEnvelope className="text-gray-600 mr-2" />
              {email}
            </p>
            <p className="text-gray-700 flex items-center mb-2">
              <FaPhone className="text-gray-600 mr-2" />
              {phoneNumber}
            </p>
            <p className="text-gray-700 flex items-center">
              <span className="mr-2">Joined:</span>
              {moment(createdAt).format("MMMM Do YYYY")}
            </p>
          </div>
        </div>
      </motion.div>

      {/* Cart Section */}
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaShoppingCart className="text-orange-600 text-xl mr-3" />
          Cart Items
        </h3>
        {currentCartItems && currentCartItems.length > 0 ? (
          <ul className="space-y-4">
            {currentCartItems.map((item) => (
              <li key={item.id} className="border-b border-gray-200 pb-4">
                <div className="flex justify-between text-gray-800">
                  <span>Item ID: {item.id}</span>
                  <span>Price: KES {item.price}</span>
                </div>
                <div className="flex justify-between text-gray-600 mt-2">
                  <span>Size ID: {item.sizeId}</span>
                  <span>Color ID: {item.colorId}</span>
                  <span>Count: {item.count}</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No items in the cart</p>
        )}
        {/* Pagination for Cart */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setCartPage((prev) => Math.max(prev - 1, 1))}
            disabled={cartPage === 1}
            className="text-blue-600 disabled:text-gray-400"
          >
            Previous
          </button>
          <span>
            Page {cartPage} of {cartPageCount}
          </span>
          <button
            onClick={() => setCartPage((prev) => Math.min(prev + 1, cartPageCount))}
            disabled={cartPage === cartPageCount}
            className="text-blue-600 disabled:text-gray-400"
          >
            Next
          </button>
        </div>
      </motion.div>

      {/* Orders Section */}
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaShoppingCart className="text-green-600 text-xl mr-3" />
          Orders
        </h3>
        {currentOrders && currentOrders.length > 0 ? (
          <ul className="space-y-4">
            {currentOrders.map((o) => (
              <li key={o.id} className="border-b border-gray-200 pb-4">
                <div className="flex flex-col gap-3 md:flex-row md:justify-between text-gray-800">
                  <span>Order ID: {o.id}</span>
                  <span>Total: {o.totalAmount}</span>
                  
                  <span
                    className={`w-1/2 md:w-full px-2 py-1 rounded-full text-white ${
                      o.status === "cancelled"
                        ? "bg-red-500"
                        : o.status === "pending"
                        ? "bg-orange-500"
                        : "bg-green-500"
                    }`}
                  >
                    {o.status}
                  </span>
                  <span>
                    {moment(o.createdAt).format("MMMM Do YYYY hh:mm:ss a")}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No orders found</p>
        )}
        {/* Pagination for Orders */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setOrderPage((prev) => Math.max(prev - 1, 1))}
            disabled={orderPage === 1}
            className="text-blue-600 disabled:text-gray-400"
          >
            Previous
          </button>
          <span>
            Page {orderPage} of {orderPageCount}
          </span>
          <button
            onClick={() => setOrderPage((prev) => Math.min(prev + 1, orderPageCount))}
            disabled={orderPage === orderPageCount}
            className="text-blue-600 disabled:text-gray-400"
          >
            Next
          </button>
        </div>
      </motion.div>

      {/* Ratings Section */}
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaStar className="text-yellow-400 text-xl mr-3" />
          Ratings
        </h3>
        {currentRatings && currentRatings.length > 0 ? (
          <ul className="space-y-4">
            {currentRatings.map((r) => (
              <li key={r.id} className="border-b border-gray-200 pb-4">
                <div className="flex justify-between text-gray-800">
                  <span>Rating: {r.rating}</span>
                  <span>Date: {moment(r.createdAt).format("MMMM Do YYYY")}</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No ratings found</p>
        )}
        {/* Pagination for Ratings */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setRatingsPage((prev) => Math.max(prev - 1, 1))}
            disabled={ratingsPage === 1}
            className="text-blue-600 disabled:text-gray-400"
          >
            Previous
          </button>
          <span>
            Page {ratingsPage} of {ratingsPageCount}
          </span>
          <button
            onClick={() => setRatingsPage((prev) => Math.min(prev + 1, ratingsPageCount))}
            disabled={ratingsPage === ratingsPageCount}
            className="text-blue-600 disabled:text-gray-400"
          >
            Next
          </button>
        </div>
      </motion.div>

      {/* Feedback Section */}
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
          <FaComment className="text-purple-600 text-xl mr-3" />
          Feedback
        </h3>
        {currentFeedback && currentFeedback.length > 0 ? (
          <ul className="space-y-4">
            {currentFeedback.map((f) => (
              <li key={f.id} className="border-b border-gray-200 pb-4">
                <div className="flex justify-between text-gray-800">
                  <span>Feedback: {f.message}</span>
                  <span>Date: {moment(f.createdAt).format("MMMM Do YYYY")}</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No feedback found</p>
        )}
        {/* Pagination for Feedback */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setFeedbackPage((prev) => Math.max(prev - 1, 1))}
            disabled={feedbackPage === 1}
            className="text-blue-600 disabled:text-gray-400"
          >
            Previous
          </button>
          <span>
            Page {feedbackPage} of {feedbackPageCount}
          </span>
          <button
            onClick={() => setFeedbackPage((prev) => Math.min(prev + 1, feedbackPageCount))}
            disabled={feedbackPage === feedbackPageCount}
            className="text-blue-600 disabled:text-gray-400"
          >
            Next
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default UserDetails;
