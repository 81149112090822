import React, { useState, useRef, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsSuitHeartFill } from "react-icons/bs";
import useSession from "../../../context/useSession";
import axios from "axios";
import { CartContext } from "../../../context/cart/CartProvider ";

const HeaderBottom = () => {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [categories, setCategories] = useState([]); // For categories
  const { cartItemCount } = useContext(CartContext);
  const { authToken } = useSession();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_products`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products || []);
      } catch (error) {
        console.error("Error fetching products", error);
        setProducts([]);
      }
    };
    fetchProducts();
  }, [authToken]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoriesResponse = await fetchCategories();
      setCategories(categoriesResponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCategories = () =>
    axios.get(
      `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filtered = products.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            onClick={() => setShow(!show)}
            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
          >
            <HiOutlineMenuAlt4 className="w-5 h-5" />
            <p className="text-[14px] font-normal">Shop by Category</p>

            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-36 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
              >
                {/* Map through the categories array */}
                {categories.map((category) => (
                  <a key={category.id} href={`${window.location.origin}/category/${category.slug}`}>
                    <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                      {category.name}
                    </li>
                  </a>
                ))}
              </motion.ul>
            )}
          </div>

          <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5" />
            {searchQuery && (
              <div className="w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer">
                {filteredProducts.map((item) => (
                  <a
                    href={`/product/${item.id}`}
                    key={item._id}
                    className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3 block"
                    onClick={() => {
                      setSearchQuery("");
                      setShowSearchBar(true);
                    }}
                  >
                    <img
                      className="w-24"
                      src={item.sizes[0].colors[0].images[0].imageurl}
                      alt="productImg"
                    />
                    <div className="flex flex-col gap-1">
                      <p className="font-semibold text-lg">{item.name}</p>
                      <p className="text-xs">
                        {item.desc.length > 100
                          ? `${item.desc.slice(0, 100)}...`
                          : item.desc}
                      </p>
                      <p className="text-sm">
                        Price:{" "}
                        <span className="text-primeColor font-semibold">
                          KES {item.sizes[0].price}
                        </span>
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>

          <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
            <a href="/cart">
              <div className="relative">
                <FaShoppingCart />
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-orange-500 text-white">
                  {cartItemCount}
                </span>
              </div>
            </a>
            <BsSuitHeartFill />
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
