import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { FaSquareCheck } from "react-icons/fa6";
import { PiWarningFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";

const PaymentModal = ({ checkoutId, setCheckoutId }) => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const navigate = useNavigate();
  const resetStatus = () => {
    setCheckoutId(null);
    sessionStorage.removeItem("checkoutId");
    window.location.href = "/shop";
  };
  //   if (!checkoutId) return;
  const checkPayementStatus = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/mpesa/single/${checkoutId}`
      );
      setPaymentDetails(data?.mpesaPaymentDetails);
      if (
        data?.mpesaPaymentDetails &&
        data?.mpesaPaymentDetails?.transactionStatus !== "pending"
      ) {
        setTimeout(resetStatus, 3000);
      }
      //   console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (checkoutId) {
      const myInterval = setInterval(checkPayementStatus, 5000);
      return () => {
        // should clear the interval when the component unmounts
        clearInterval(myInterval);
      };
    }
  }, [checkoutId]);
  return (
    <div className="flex px-10 items-center justify-center">
      <div className="w-full bg-gray-50 p-6 rounded-lg shadow-lg flex items-center flex-col gap-5">
        <h3 className="text-2xl font-semibold text-gray-700 mb-6 ">
          Processing Payment
        </h3>
        {paymentDetails?.transactionStatus &&
        paymentDetails?.transactionStatus !== "pending" ? (
          <>
            <div className="text-5xl ">
              {paymentDetails?.transactionStatus === "paid" ? (
                <FaSquareCheck className="text-green-500" />
              ) : (
                <PiWarningFill className="text-red-500" />
              )}
            </div>
            {paymentDetails?.transactionStatus === "paid" ? (
              <h3 className="text-lg text-gray-700 mb-6">
                Payment Successfull
              </h3>
            ) : (
              <h3 className="text-lg text-gray-700 mb-6">
                {paymentDetails?.transactionStatus === "cancelled"
                  ? "You seem to have cancelled the payment."
                  : "Oops! Payment failled. Don't worry just"}
                <Link className="text-sky-600 hover:underline" to={"/cart"}>
                  Try again
                </Link>
              </h3>
            )}
          </>
        ) : (
          <>
            <div className="w-max">
              <BiLoaderAlt className=" text-4xl text-blue-500 animate-spin" />
            </div>
            <h3 className="text-lg text-gray-700 mb-6">
              Wait a minute while we process your payment.
            </h3>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentModal;
