import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import useSession from "../../../context/useSession";
import axios from "axios";
import Outline from "./Outline";
 
const Products = () => {
  const { authToken } = useSession();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
           `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/get_products`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setProducts(response.data.products || []);
      } catch (error) {
        console.error("Error fetching products", error);
        setProducts([]);
      }
    };

    fetchProducts();
  }, [authToken]);

  const getPriceRange = (sizes) => {
    if (!sizes || sizes.length === 0) return "N/A";

    const prices = sizes.map((size) => size.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    return minPrice === maxPrice ? `${minPrice}` : `${minPrice} - ${maxPrice}`;
  };

  return (
    <div className="w-full pb-16">
      <Heading heading="Products" />
      <div className="flex flex-wrap justify-between gap-4 px-2">
        {products.map((product) => (
          <div
            key={product.id}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
          >
            <Outline
              _id={product.id}
              img={product.sizes?.[0]?.colors?.[0]?.images?.[0]?.imageurl || ""}
              productName={product.name || "Unknown Product"}
              price={getPriceRange(product.sizes)}
              badge={true}
              des={product.desc || "No description available."}
              averageRating={product.averageRating || 0}
              ratingPercentages={product.ratingPercentages || []}
              priceTracker={product.sizes?.[0]?.priceTracker}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
