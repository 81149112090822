import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useSession from "../../../context/useSession";
import { getTextColorFromHex } from "../../../utils/getTextColor";

const steps = ["Step 1", "Step 2", "Step 3"];

const RegisterProduct = () => {
  const { authToken } = useSession();

  const [activeStep, setActiveStep] = useState(0);
  const [sizes, setSizes] = useState([
    { sizeNameId: "", count: "", price: "", colors: [], sizeImage: null },
  ]);
  const [categories, setCategories] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [colorsList, setColorsList] = useState([]);

  // Form state
  const [formState, setFormState] = useState({
    category: "",
    productName: "",
    productDescription: "",
    specifications: {
      material: "",
      noOfPockets: "",
      handle: "",
      wheels: "",
      straps: "",
      lock: "",
      waterResistance: "",
    },
    sizes: [
      { sizeNameId: "", count: "", price: "", colors: [], sizeImage: null },
    ],
  });



  useEffect(() => {
    const fetchSizesCategoriesColors = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const [sizesRes, categoriesRes, colorsRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/sizes`,
            { headers }
          ),
          axios.get(
            `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/categories`,
            {
              headers,
            }
          ),
          axios.get(
            `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/colors`,
            { headers }
          ),
        ]);
        setSizesList(sizesRes.data.data);
        setCategories(categoriesRes.data.data);
        setColorsList(colorsRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSizesCategoriesColors();
    const intervalId = setInterval(fetchSizesCategoriesColors, 100000);
    return () => clearInterval(intervalId);
  }, [authToken]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSpecificationChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      specifications: {
        ...prevState.specifications,
        [name]: value,
      },
    }));
  };

  const addSize = () => {
    setSizes([
      ...sizes,
      { sizeNameId: "", count: "", price: "", colors: [], sizeImage: null },
    ]);
    setFormState((prevState) => ({
      ...prevState,
      sizes: [
        ...sizes,
        { sizeNameId: "", count: "", price: "", colors: [], sizeImage: null },
      ],
    }));
  };

  const addColor = (sizeIndex) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].colors.push({ colorNameId: "", colorCount: "" });
    setSizes(updatedSizes);
    setFormState((prevState) => ({
      ...prevState,
      sizes: updatedSizes,
    }));
  };

  const handleColorChange = (sizeIndex, colorIndex, e) => {
    const { name, value } = e.target;
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].colors[colorIndex][name] = value;
    setSizes(updatedSizes);
    setFormState((prevState) => ({
      ...prevState,
      sizes: updatedSizes,
    }));
  };

  const handleCountChange = (sizeIndex, colorIndex, e) => {
    const { name, value } = e.target;
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].colors[colorIndex][name] = value;
    setSizes(updatedSizes);
    setFormState((prevState) => ({
      ...prevState,
      sizes: updatedSizes,
    }));
  };

  const handleImageChange = (sizeIndex, colorIndex, e) => {
    const file = e.target.files[0];
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].colors[colorIndex].colorImage = file;
    setSizes(updatedSizes);
    setFormState((prevState) => ({
      ...prevState,
      sizes: updatedSizes,
    }));
  };

  //  registering a product
  const onSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append("productName", formState.productName);
      formData.append("productDescription", formState.productDescription);
      formData.append("categoryId", formState.category);

      Object.keys(formState.specifications).forEach((key) => {
        formData.append(
          `specifications[${key}]`,
          formState.specifications[key]
        );
      });

      sizes.forEach((size, sizeIndex) => {
        formData.append(`sizes[${sizeIndex}].sizeNameId`, size.sizeNameId);
        formData.append(`sizes[${sizeIndex}].count`, size.count);
        formData.append(`sizes[${sizeIndex}].price`, size.price);

        // if (size.sizeImage) {
        //   formData.append(`sizes[${sizeIndex}].sizeImage`, size.sizeImage);
        // }

        size.colors.forEach((color, colorIndex) => {
          formData.append(
            `sizes[${sizeIndex}].colors[${colorIndex}].colorNameId`,
            color.colorNameId
          );
          formData.append(
            `sizes[${sizeIndex}].colors[${colorIndex}].colorCount`,
            color.colorCount
          );
          if (color.colorImage) {
            formData.append(
              `sizes[${sizeIndex}].colors[${colorIndex}].colorImage`,
              color.colorImage
            );
          }
        });
      });

      const response = await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/products/create_product`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Show success notification
      Swal.fire({
        title: "Success!",
        text: "Product registered successfully.",
        icon: "success",
        confirmButtonText: "Okay",
      }).then(() => {
        // Reload the page and navigate to /admin after the user clicks "Okay"
        window.location.href = "/admin";
      });
    } catch (error) {
      // Show error notification
      Swal.fire({
        title: "Error!",
        text: "Failed to register product. Please try again.",
        icon: "error",
        confirmButtonText: "Okay",
      });

      console.error("Error registering product:", error);
    }
  };

  return (
    <div className="bg-[#F5F5F3] py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white p-8 shadow-xl rounded-lg">
        <div className="flex mb-8">
          {steps.map((label, index) => (
            <div
              key={label}
              className={`flex-1 text-center ${
                activeStep === index ? "font-bold" : ""
              }`}
            >
              {label}
              {index < steps.length - 1 && (
                <div className="inline-block w-1 h-4 bg-gray-400 mx-2"></div>
              )}
            </div>
          ))}
        </div>
        <div className="mt-8">
          {activeStep === 0 && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 items-center  gap-4">
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Category
                  </label>
                  <select
                    name="category"
                    value={formState.category}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mt-2">
                  <label className="block text-gray-700 font-medium mb-1">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="productName"
                    value={formState.productName}
                    onChange={handleChange}
                    placeholder="Enter product name"
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
              </div>

              <div className="mt-2">
                <label className="block text-gray-700 font-medium mb-1">
                  Product Description
                </label>
                <textarea
                  name="productDescription"
                  value={formState.productDescription}
                  onChange={handleChange}
                  placeholder="Enter a brief description of the product"
                  rows="4"
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {Object.keys(formState.specifications).map((key) => (
                  <div key={key}>
                    <label className="block text-gray-700 font-medium mb-2">
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={formState.specifications[key]}
                      onChange={handleSpecificationChange}
                      placeholder={`Enter ${key}`}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <div>
              <h6>Sizes & Colors</h6>
              {sizes.map((size, sizeIndex) => (
                <div key={sizeIndex} className="border p-4 mb-4 rounded">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-gray-700 font-medium mb-1">
                        Size ID
                      </label>
                      <select
                        value={size.sizeNameId}
                        onChange={(e) => {
                          const updatedSizes = [...sizes];
                          updatedSizes[sizeIndex].sizeNameId = e.target.value;
                          setSizes(updatedSizes);
                          setFormState((prevState) => ({
                            ...prevState,
                            sizes: updatedSizes,
                          }));
                        }}
                        className="w-full p-2 border border-gray-300 rounded"
                      >
                        <option value="">Select Size ID</option>
                        {sizesList.map((sizeItem) => (
                          <option key={sizeItem.id} value={sizeItem.id}>
                            {sizeItem.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-gray-700 font-medium mb-1 mt-2">
                        Count
                      </label>
                      <input
                        type="number"
                        value={size.count}
                        onChange={(e) => {
                          const updatedSizes = [...sizes];
                          updatedSizes[sizeIndex].count = e.target.value;
                          setSizes(updatedSizes);
                          setFormState((prevState) => ({
                            ...prevState,
                            sizes: updatedSizes,
                          }));
                        }}
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-medium mb-1 mt-2">
                        Price
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        value={size.price}
                        onChange={(e) => {
                          const updatedSizes = [...sizes];
                          updatedSizes[sizeIndex].price = e.target.value;
                          setSizes(updatedSizes);
                          setFormState((prevState) => ({
                            ...prevState,
                            sizes: updatedSizes,
                          }));
                        }}
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                  </div>
                  {/* <label className="block text-gray-700 font-medium mb-1 mt-2">
                    Size Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(sizeIndex, e)}
                    className="w-full p-2 border border-gray-300 rounded"
                  /> */}

                  {size.colors.map((color, colorIndex) => (
                    <div key={colorIndex} className="mt-4">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                          <label className="block text-gray-700 font-medium mb-1">
                            Color
                          </label>
                          <select
                            name="colorNameId"
                            value={color.colorNameId}
                            onChange={(e) =>
                              handleColorChange(sizeIndex, colorIndex, e)
                            }
                            className="w-full p-2 border border-gray-300 rounded"
                          >
                            <option value="">Select Color</option>
                            {colorsList.map((colorOption) => (
                              <option
                                key={colorOption.id}
                                value={colorOption.id}
                                style={{
                                  background: colorOption.name,
                                  color: getTextColorFromHex(colorOption.name),
                                }}
                              >
                                {colorOption?.title || "N/A"}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label className="block text-gray-700 font-medium mb-1">
                            Count
                          </label>
                          <input
                            type="text"
                            name="colorCount"
                            value={color.colorCount}
                            onChange={(e) =>
                              handleColorChange(sizeIndex, colorIndex, e)
                            }
                            placeholder="Enter count"
                            className="w-full p-2 border border-gray-300 rounded"
                          />
                        </div>

                        <div>
                          <label className="block text-gray-700 font-medium mb-1">
                            Color Image
                          </label>
                          <input
                            type="file"
                            name="colorImage"
                            onChange={(e) =>
                              handleImageChange(sizeIndex, colorIndex, e)
                            }
                            className="w-full p-2 border border-gray-300 rounded"
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={() => addColor(sizeIndex)}
                    className="mt-4 bg-blue-500 text-white p-2 rounded"
                  >
                    Add Color
                  </button>
                </div>
              ))}

              <button
                type="button"
                onClick={addSize}
                className="bg-blue-500 text-white p-2 rounded"
                style={{ width: "100%" }}
              >
                Add Size
              </button>
            </div>
          )}

          <div className="mt-8 flex justify-evenly">
            <button
              type="button"
              onClick={handleBack}
              disabled={activeStep === 0}
              className="bg-slate-500 text-white p-2 rounded mr-2"
              style={{ width: "100%" }}
            >
              Back
            </button>
            {activeStep < steps.length - 1 ? (
              <button
                type="button"
                onClick={handleNext}
                className="bg-emerald-600 text-white p-2 rounded"
                style={{ width: "100%" }}
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                onClick={onSubmit}
                className="bg-emerald-600 text-white p-2 rounded"
                style={{ width: "100%" }}
              >
                Submit
              </button>
            )}
            {activeStep > 0 && (
              <button
                type="button"
                onClick={handleReset}
                className="bg-red-500 text-white p-2 rounded ml-2"
                style={{ width: "100%" }}
              >
                Reset
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterProduct;
