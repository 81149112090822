import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { notification } from "antd";
import useSession from "../../context/useSession";

const FeedbackPage = () => {
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { authToken } = useSession();

  const openNotification = (type, message) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_VINTARA_SERVER_ADDRESS}/api/auth/feedback`,
        { feedback },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setFeedback("");
      setError(null);
      openNotification("success", "Feedback submitted successfully!");
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to submit feedback.";
      setError(errorMessage);
      openNotification("error", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      className="flex flex-col md:flex-row items-start justify-center h-screen bg-gray-100 p-4 pt-24"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="md:w-1/2 md:pr-8">
        <h1 className="text-4xl font-bold mb-6 text-gray-800">
          We Value Your Feedback
        </h1>
        <p className="text-lg text-left mb-8">
          Your opinion matters to us! Here’s why we would love to hear from you:
        </p>
        <motion.ul
          className="mb-8 space-y-4"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <li className="flex items-center">
            <span className="mr-2 text-blue-600">✔️</span>
            Help us improve our products and services.
          </li>
          <li className="flex items-center">
            <span className="mr-2 text-blue-600">✔️</span>
            Share your experience with our Vintara bags.
          </li>
          <li className="flex items-center">
            <span className="mr-2 text-blue-600">✔️</span>
            Let us know what you love and what we can do better.
          </li>
          <li className="flex items-center">
            <span className="mr-2 text-blue-600">✔️</span>
            Be a part of our journey to provide the best bags across Africa.
          </li>
        </motion.ul>
      </div>

      <motion.form
        onSubmit={handleSubmit}
        className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg md:w-1/2 space-y-4"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <label className="block text-sm font-semibold text-gray-700">
          Your Feedback
        </label>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="5"
          placeholder="Share your thoughts..."
          required
        />
        <button
          type="submit"
          className={`w-full py-3 px-4 text-white rounded-md font-semibold ${
            loading
              ? "bg-gray-500 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit Feedback"}
        </button>
        {error && <p className="mt-4 text-red-600 text-sm">{error}</p>}

        <motion.div
          className="mt-6 text-center w-full"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-gray-600">
            Thank you for taking the time to share your thoughts with us!
          </p>
        </motion.div>
      </motion.form>
    </motion.div>
  );
};

export default FeedbackPage;
