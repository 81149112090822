import React, { useState } from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { MdOutlineLabelImportant } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Badge from "../Products/Badge";
import { BsFillStarFill } from "react-icons/bs";
import { FaTag } from "react-icons/fa";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Make modal width responsive
  maxWidth: "600px", // Set a maximum width
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  border: "none",
  borderRadius: "8px",
  overflow: "hidden",
}));

const NewProduct = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishList, setWishList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const {
    _id,
    productName,
    img,
    badge,
    price,
    averageRating,
    ratingPercentages = [],
    priceTracker,
  } = props;

  const hasDiscount = priceTracker && priceTracker.status === "decrease";

  const idString = (_id) => String(_id).toLowerCase().split(" ").join("");
  const rootId = idString(_id);

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: props,
      },
    });
  };

  const handleWishList = () => {
    toast.success("Product added to wishlist");
    setWishList([...wishList, props]);
  };

  const getRatingPercentages = () => {
    // Define a color array for different rating bars
    const colors = ["#FF5733", "#FF8C00", "#FFD700", "#90EE90", "#32CD32"];

    return ratingPercentages.map((rate, index) => (
      <div key={index} className="flex items-center mb-2">
        <span className="text-sm font-semibold w-16">{index + 1} Star</span>
        <div
          className="relative flex-grow bg-gray-200 rounded-full h-3 mx-2"
          style={{ backgroundColor: colors[index] }}
        >
          <div
            style={{
              width: `${rate.percentage}%`,
              backgroundColor: colors[index],
            }} // Set the bar color
            className="absolute top-0 left-0 h-full rounded-full"
          />
        </div>
        <span className="text-sm font-semibold w-16 text-right">
          {rate.percentage.toFixed(2)}%
        </span>
      </div>
    ));
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= rating ? (
          <AiFillStar key={i} className="text-orange-500" />
        ) : (
          <AiOutlineStar key={i} className="text-orange-500" />
        )
      );
    }
    return stars;
  };

  return (
    <div className="w-full relative group">
      <div className="relative overflow-hidden">
        <div onClick={handleProductDetails}>
          <Image className="w-full h-full shadow-2xl" imgSrc={img} />
        </div>
        <div className="absolute top-6 left-8">
          {badge && (
            <Badge
              text={
                <span className="flex items-center gap-1">
                  New <BsFillStarFill />
                </span>
              }
            />
          )}
        </div>
        <div className="w-full h-32 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
          <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li
              onClick={() =>
                dispatch(
                  addToCart({
                    _id,
                    name: productName,
                    quantity: 1,
                    image: img,
                    badge,
                    price,
                  })
                )
              }
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              Add to Cart
              <FaShoppingCart />
            </li>
            <li
              onClick={() => setOpenModal(true)}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              View Details
              <MdOutlineLabelImportant className="text-lg" />
            </li>
            <li
              onClick={handleWishList}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              Add to Wish List
              <BsSuitHeartFill />
            </li>
          </ul>
        </div>
      </div>
      <div className="py-6 flex flex-col gap-1 border border-t-0 px-4">
        <div className="flex items-center justify-between font-titleFont">
          <h2 className="text-lg text-primeColor font-bold">{productName}</h2>
          <p className="text-[#767676] text-[14px]">KES {price}</p>
        </div>
        <div className="flex items-center">
          <span className="cursor-pointer text-primeColor flex items-center">
            {renderStars(averageRating)}
          </span>
        </div>
        <div className="pt-4 flex flex-col gap-2">
          <button className="w-full">
            <a
              href={`/product/${rootId}`}
              className="flex items-center justify-center gap-2 bg-slate-100 py-2 rounded-md hover:bg-slate-200"
            >
              <FaShoppingCart />
              <span>Add to Cart</span>
            </a>
          </button>
          <div className="flex items-center justify-center">
            {hasDiscount && (
              <div className="flex items-center gap-2 text-orange-600 font-medium bg-orange-100 p-1 rounded">
                <FaTag className="text-orange-500" />
                <span>On offer</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="product-details-modal"
        aria-describedby="modal-description"
      >
        <StyledBox>
          <div className="flex items-center mb-4">
            <span className="cursor-pointer text-primeColor flex items-center">
              {renderStars(averageRating)}{" "}
              <div className="pl-4 font-bold">{averageRating} out of 5</div>
            </span>
          </div>

          <div className="flex flex-col gap-2 mb-4">
            {getRatingPercentages()}
          </div>

          <a href={`/product/${rootId}`}>
            <button className="w-full py-2 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-colors">
              View More
            </button>
          </a>
        </StyledBox>
      </Modal>
    </div>
  );
};

export default NewProduct;
